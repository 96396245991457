import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const Loading = ({
  size = 60,
  thickness = 4,
  color = "primary",
  fullScreen = false,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight={fullScreen ? "100vh" : "50vh"}
      padding={2}
    >
      <CircularProgress size={size} thickness={thickness} color={color} />
    </Box>
  );
};

export default Loading;
