const allowedUrl = ["experience", "profile", "rules&policies"];

const checkUrl = ({ pathname }) => {
    if (pathname.startsWith('/')) {
        pathname = pathname.substring(1);
    }

    const foundUrl = allowedUrl.find(url => pathname.startsWith(url));
    return foundUrl ? foundUrl : null;
}

export { checkUrl };
