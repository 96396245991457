import { Box, CssBaseline, Toolbar, useMediaQuery } from "@mui/material";
import React from "react";
import Header from "./Header";
import { useTheme } from "@emotion/react";
import MobileNavigation from "./MobileNavigation";
import MobileHeader from "./MobileHeader";

const Layout = ({ children }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", background: "secondary.100" }}>
      <CssBaseline />
      { isMobile ? <MobileHeader /> : <Header />}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "secondary.100",
          padding: {
            sm: "0px",
          },
        }}
      >
        <Toolbar />
        {children}
        {isMobile && <MobileNavigation />}
      </Box>
    </Box>
  );
};

export default Layout;
