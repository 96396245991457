import { Paper } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { CgProfile } from "react-icons/cg";
import { FaCircleDollarToSlot } from "react-icons/fa6";
import { MdOutlineScience } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

export default function MobileNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const getValueFromStorage = () => {
    const storedValue = localStorage.getItem("mobile-selected");
    return storedValue !== null
      ? parseInt(storedValue, 10)
      : getValueFromPath();
  };

  const getValueFromPath = () => {
    switch (location.pathname) {
      case process.env.REACT_APP_PREFIX + "/payment":
        return 0;
      case process.env.REACT_APP_PREFIX + "/":
        return 1;
      case process.env.REACT_APP_PREFIX + "/profile":
        return 2;
      default:
        return 1;
    }
  };

  const [value, setValue] = React.useState(getValueFromStorage);

  const { t } = useTranslation();


  if (isAuthenticated) {
    return (
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
          zIndex: 2000,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={parseInt(localStorage.getItem("mobile-selected"))}
          onChange={(event, newValue) => {
            localStorage.setItem("mobile-selected", newValue);
          }}
        >
          <BottomNavigationAction
            onClick={() => {
              navigate(process.env.REACT_APP_PREFIX + "/payment");
            }}
            label={t("menu_payment")}
            icon={<FaCircleDollarToSlot />}
          />
          <BottomNavigationAction
            onClick={() => {
              navigate(process.env.REACT_APP_PREFIX + "/");
            }}
            label="Home"
            icon={<MdOutlineScience />}
          />

          <BottomNavigationAction
            onClick={() => {
              navigate(process.env.REACT_APP_PREFIX + "/profile");
            }}
            label={t("menu_profile")}
            icon={<CgProfile />}
          />
        </BottomNavigation>
      </Paper>
    );
  } else {
    return null;
  }
}
