import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import axiosInstance from "../../api/axiosInstance";
import { useThunk } from "../../hooks/useThunk";
import { getExperienceInvitation } from "../../store/thunks/experience/getExperienceInvitation";
import { getExperienceRegistration } from "../../store/thunks/experience/getExperienceRegistration";
import { extractDateTime } from "../utils/extractDateTime";
import { extracDeadLineTime } from "../utils/extractDeadLineTime";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../utils/capitalizerLetter";

const actionInvitationParticipant = async (sessionId, actionType) => {
  try {
    const formData = new FormData();
    formData.append("session_id", sessionId);

    const response = await axiosInstance.post(
      `/participant/${actionType}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error registering participant:", error);
    return { error: error.response ? error.response.data : "Unknown error" };
  }
};

const InscriptionDialog = ({ open, onClose, experience, onConfirm, type }) => {
  const [loading, setLoading] = useState(false);
  const [runInvitation, isLoadingInvitation, errorInvitation] = useThunk(
    getExperienceInvitation
  );
  const [runRegistration, isLoadingRegistration, errorRegistration] = useThunk(
    getExperienceRegistration
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleConfirmClick = async () => {
    if (experience && experience.session_id) {
      setLoading(true);
      const result = await actionInvitationParticipant(
        experience.session_id,
        !type ? "cancel" : "register"
      );

      if (result) {
        await runInvitation();
        await runRegistration();
      }
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle
        sx={{
          fontWeight: "bold",
          fontSize: isMobile ? "1.2rem" : "1.5rem", 
        }}
      >
        {type === "confirm"
          ? t("do_you_really_want_to_register_for_experiment")
          : t("session_enrolment_cancellation")}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: isMobile ? 2 : 3 }}>
        {experience && (
          <>
            {!type && (
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                  fontSize: isMobile ? "1rem" : "1.2rem",
                }}
              >
                {t("do_you_really_want_to_cancel_session_enrolment")}
              </Typography>
            )}
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 2,
                fontSize: isMobile ? "1rem" : "1.2rem",
              }}
            >
              {capitalizeFirstLetter(
                t("experiment").replace(/<\/?[^>]+(>|$)/g, "")
              )}{" "}
              {experience.experiment_public_name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
                flexDirection: isMobile ? "column" : "row", 
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "0.9rem" : "1rem",
                }}
              >
                {t("date_and_time").replace(/<\/?[^>]+(>|$)/g, "")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: isMobile ? "0.9rem" : "1rem",
                  mt: isMobile ? 0.5 : 0, 
                }}
              >
                {`${extractDateTime(experience.session_name).date} - ${
                  extractDateTime(experience.session_name).time
                }`}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "0.9rem" : "1rem",
                }}
              >
                {t("location").replace(/<\/?[^>]+(>|$)/g, "")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: isMobile ? "0.9rem" : "1rem",
                  mt: isMobile ? 0.5 : 0,
                }}
              >
                {experience.lab_name}
              </Typography>
            </Box>

            {type && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 1,
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: isMobile ? "0.9rem" : "1rem",
                  }}
                >
                  {capitalizeFirstLetter(t("registration_until"))}:{" "}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: isMobile ? "0.9rem" : "1rem",
                    mt: isMobile ? 0.5 : 0,
                  }}
                >
                  {extracDeadLineTime(experience.registration_unixtime)}
                </Typography>
              </Box>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "space-between",
          p: isMobile ? 2 : 3,
          flexDirection: isMobile ? "column" : "row", 
          mb:isMobile ? 10 : 0
        }}
      >
        <Button
          onClick={onClose}
          color="error"
          variant="outlined"
          sx={{
            fontSize: isMobile ? "0.8rem" : "1rem", 
            width: isMobile ? "100%" : "auto", 
            mb: isMobile ? 3 : 0, 
          }}
          disabled={loading}
        >
          {t("no_sorry")}
        </Button>
        <Button
          onClick={handleConfirmClick}
          color="primary"
          variant="contained"
          sx={{
            fontSize: isMobile ? "0.8rem" : "1rem", 
            width: isMobile ? "100%" : "auto",
          }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : t("yes_i_want")}
        </Button>
      </DialogActions>

      {loading && (
        <Backdrop
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1201,
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Dialog>
  );
};

export default InscriptionDialog;
