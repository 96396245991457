import {createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../api/axiosInstance';

export const login = createAsyncThunk(
    'user/login',
    async (credentials, thunkAPI) => {
      try {
        const response = await axiosInstance.post('/auth/login', credentials);
        const { token, user } = response.data;

        localStorage.setItem('token', token);
        return { token, user };
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );