import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  styled,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import IBAN from "iban";
import React, { useEffect, useState } from "react";
import { IoIosSave } from "react-icons/io";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../../api/axiosInstance";
import { useThunk } from "../../../hooks/useThunk";
import { getProfile } from "../../../store/thunks/user/getProfile";
import Loading from "../../other/Loading";
import Address from "./Address";
import PaymentInformation from "./PaymentInformation";
import PersonalInformation from "./PersonalInformation";
import PersonalInformationEdu from "./PersonalInformationEdu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const CTAButton = styled(Button)(({ theme, inVuew }) => ({
  backgroundColor: theme.palette.primary["500"],
  color: theme.palette.text.secondary,
  "& svg": {
    color: theme.palette.text.secondary,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary["100"],
    color: theme.palette.text.primary,
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
}));

const ProfileDashboardWeb = () => {
  const [navigation, setNavigation] = useState(0);
  const { userInfo } = useSelector((state) => state.user);
  const [form, setForm] = useState([]);
  const [errors, setErrors] = useState({});
  const [eduForm, setEduForm] = useState({});
  const [runGetProfile, isLoadingProfile, errorProfile] = useThunk(getProfile);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();

  const personalInformationSchema = Yup.object().shape({
    mother_tongue: Yup.string().trim().required("Mother tongue is required"),
    other_language: Yup.string().trim(),
    gender: Yup.string()
      .oneOf(["m", "f", "non-binary", "other"])
      .required(t("you_have_to_gender")),
    institution: Yup.string().trim().required(t("error_no_institution")),
    field_of_studies: Yup.string()
      .trim()
      .required(t("you_have_to_field_of_studies")),
    begin_of_studies: Yup.number()
      .required(t("you_have_to_begin_of_studies"))
      .min(1900, "Invalid year")
      .max(new Date().getFullYear(), "Year cannot be in the future"),
  });

  const addressSchema = Yup.object().shape({
    street_name: Yup.string().trim().required(t("street_name_empty_error")),
    postal_code: Yup.string().trim().required(t("postal_code_empty_error")),
    city: Yup.string().trim().required(t("city_empty_error")),
    country: Yup.string().trim().required(t("country_empty_error")),
  });

  const paymentInformationSchema = Yup.object().shape({
    is_account_holder_same_name: Yup.string()
      .oneOf(["y", "n"])
      .required("Account holder status is required"),

    iban: Yup.string().when("is_account_holder_same_name", {
      is: "y",
      then: (schema) =>
        schema
          .required("IBAN is required")
          .test("is-valid-iban", "Invalid IBAN", (value) =>
            IBAN.isValid(value)
          ),
      otherwise: (schema) =>
        schema
          .notRequired()
          .test("is-valid-iban", "Invalid IBAN", (value) =>
            IBAN.isValid(value)
          ),
    }),

    account_holder_fname: Yup.string().when("is_account_holder_same_name", {
      is: "n",
      then: (schema) => schema.required(t("either_holder_lname_fname")),
      otherwise: (schema) => schema.notRequired(),
    }),

    account_holder_lname: Yup.string().when("is_account_holder_same_name", {
      is: "n",
      then: (schema) => schema.required(t("either_holder_lname_fname")),
      otherwise: (schema) => schema.notRequired(),
    }),

    country_of_bank: Yup.string().required(t("bank_country_missing")),

    account_number: Yup.string().when("country_of_bank", {
      is: (value) => value !== "CH",
      then: (schema) => schema.required(t("bank_international_data_missin")),
      otherwise: (schema) => schema.notRequired(),
    }),

    swift: Yup.string().when("country_of_bank", {
      is: (value) => value !== "CH",
      then: (schema) => schema.required(t("bank_international_data_missin")),
      otherwise: (schema) => schema.notRequired(),
    }),

    bank_clearing: Yup.string().when("country_of_bank", {
      is: (value) => value !== "CH",
      then: (schema) => schema.required(t("bank_international_data_missin")),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  useEffect(() => {
    if (userInfo.length === 0) {
      runGetProfile();
    }
  }, []);

  useEffect(() => {
    if (userInfo.length !== 0) {
      setForm(userInfo?.slice(0, 3));
      setEduForm(userInfo[3]);
    }
  }, [userInfo]);

  useEffect(() => {
    if (form.length !== 0) {
      setForm((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[navigation] = form[navigation];
        return updatedForm;
      });
      setErrors({});
    }
  }, [navigation]);

  const handleNavigationChange = (event, newValue) => {
    setNavigation(newValue);
  };

  const handleDataChange = (newData) => {
    if (newData?.target?.name === "is_account_holder_same_name") {
      const { name, value } = newData.target;
      setForm((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[navigation] = {
          ...updatedForm[navigation],
          [name]: value,
        };
        return updatedForm;
      });
    } else {
      setForm((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[navigation] = newData;
        return updatedForm;
      });
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const dataToValidate = {
      personal: form[0],
      address: form[1],
      paymentInformation: form[2],
    };

    try {
      if (navigation === 0) {
        await personalInformationSchema.validate(dataToValidate.personal, {
          abortEarly: false,
        });
      }
      if (navigation === 1) {
        await addressSchema.validate(dataToValidate.address, {
          abortEarly: false,
        });
      } else if (navigation === 2) {
        await paymentInformationSchema.validate(
          dataToValidate.paymentInformation,
          { abortEarly: false }
        );
      }
      setErrors({});

      const formData = new FormData();

      Object.keys(userInfo[4]).forEach((key) => {
        formData.append(key, userInfo[4][key]);
      });

      Object.keys(form[navigation]).forEach((key) => {
        formData.append(key, form[navigation][key]);
      });

      const data = await axiosInstance.post("/participant/profile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Your changes have been saved successfully.");
    } catch (error) {
      if (error?.inner) {
        const validationErrors = {};

        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      }

      toast.error("An error occurred while saving your changes.");
    } finally {
      setLoading(false);
    }
  };

  if (form.length === 0 || isLoadingProfile) {
    return <Loading />;
  }

  if (loading) {
    return (
      <Box
        sx={{
          backgroundColor: "secondary.100",
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Container
      sx={{
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        padding: {
          xs: "10px",
          sm: "20px",
        },
        maxWidth: "1400px !important",
      }}
    >
      <ToastContainer />

      <Grid
        container
        spacing={3}
        sx={{
          flexGrow: 1,
          marginLeft: isWeb ? -24 : 0,
          width: isWeb ? "calc(100% + 24px)" : "unset",
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            backgroundColor: "secondary.200",
            padding: {
              xs: "15px",
              sm: "20px",
            },
            borderRadius: "8px",
            marginRight: {
              xs: "0px",
              md: "20px",
            },
            marginBottom: {
              xs: "20px",
              md: "0px",
            },
            display: "flex",
            flexDirection: "column",
            "& > .MuiGrid-item": {
              paddingLeft: isWeb ? 24 : 0,
            },
          }}
        >
          <Box
            mb={2}
            p={2}
            sx={{
              backgroundColor: "table.tr",
              borderRadius: "8px",
              fontSize: {
                xs: "12px",
                sm: "14px",
              },
            }}
          >
            <Typography variant="body2" color="primary">
              The following fields can only be modified on SWITCH edu-ID.
            </Typography>
          </Box>

          <Box mb={2} sx={{ flexGrow: 1 }}>
            <PersonalInformationEdu data={eduForm} />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md
          sx={{
            backgroundColor: "secondary.200",
            padding: {
              xs: "15px",
              sm: "20px",
            },
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            "& > .MuiGrid-item": {
              paddingLeft: isWeb ? 24 : 0,
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: 5 }}
          >
            <Tabs
              value={navigation}
              onChange={handleNavigationChange}
              sx={{
                "& .MuiTab-root": {
                  color: "text.primary",
                  "&.Mui-selected,&:hover": {
                    color: "primary.500",
                  },
                },
              }}
            >
              <Tab label="Personal" />
              <Tab label="Address" />
              <Tab label="Payment" />
            </Tabs>
            {isWeb && (
              <CTAButton
                variant="contained"
                startIcon={<IoIosSave />}
                sx={{ textTransform: "none" }}
                onClick={handleSave}
              >
                {t("save")}
              </CTAButton>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            {navigation === 0 && (
              <PersonalInformation
                data={form[0]}
                setData={handleDataChange}
                errors={errors}
              />
            )}
            {navigation === 1 && (
              <Address
                data={form[1]}
                setData={handleDataChange}
                errors={errors}
              />
            )}
            {navigation === 2 && (
              <PaymentInformation
                data={form[2]}
                personalInfo={eduForm}
                setData={handleDataChange}
                errors={errors}
              />
            )}
            {!isWeb && (
              <Box
                marginBottom={10}
                marginTop={5}
                display={"flex"}
                justifyContent={"center"}
              >
                <Button
                  variant="contained"
                  startIcon={<IoIosSave />}
                  sx={{ textTransform: "none", width: "100%" }}
                  onClick={handleSave}
                >
                  {t("save")}
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfileDashboardWeb;
