import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExperienceDone } from "../store/thunks/experience/getExperienceDone";
import { getExperienceInvitation } from "../store/thunks/experience/getExperienceInvitation";
import { getExperienceRegistration } from "../store/thunks/experience/getExperienceRegistration";
import { getExperiencePayment } from "../store/thunks/experience/getExperiencePayment";

const useExperienceData = () => {
  const dispatch = useDispatch();

  const {
    doneExperiences,
    experienceInvitations,
    experienceRegistrations,
    confirmationExperiences,
    loading,
    error,
  } = useSelector((state) => state.experience);
  
  const isInitializedRef = useRef(false);

  useEffect(() => {
    if (!isInitializedRef.current) {
      const fetchAllData = async () => {
        if (!doneExperiences.length) {
          await dispatch(getExperienceDone());
        }
        if (!experienceInvitations.length) {
          await dispatch(getExperienceInvitation());
        }
        if (!experienceRegistrations.length) {
          await dispatch(getExperienceRegistration());
        }
        if (!confirmationExperiences.length) {
          await dispatch(getExperiencePayment());
        }
        isInitializedRef.current = true;
      };

      try {
        fetchAllData();
      } catch (error) {
        console.log(error);
      }
    }
  }, [dispatch]);

  return {
    doneExperiences,
    experienceInvitations,
    experienceRegistrations,
    confirmationExperiences,
    loading,
    error,
  };
};

export default useExperienceData;
