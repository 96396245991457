import { Box, Button, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { getStatusExperience, getStatusPayment } from "../components/utils/getStatus";
import { extractDateTime } from "../components/utils/extractDateTime";
import { useTranslation } from "react-i18next";

const columnHelper = createColumnHelper();

const useColumnsType = (type, fc) => {
    const {t} = useTranslation()
  const columns = useMemo(() => {
    switch (type) {
      case "payment":
        return [
          columnHelper.accessor("experiment_public_name", {
            header: t("experiment").replace(/<\/?[^>]+(>|$)/g, ""),
            cell: (info) => info.getValue(),
            enableSorting: true,
            enableGrouping: true,
          }),
          columnHelper.accessor("payment_amt", {
            header: t("payment_amt"),
            enableSorting: true,
            enableGrouping: false,
            cell: (info) => (
              <Typography className="font-box">{info.getValue()}.-</Typography>
            ),
            aggregatedCell: (info) => (
              <Typography className="font-box">{info.getValue()}.-</Typography>
            ),
          }),
          columnHelper.accessor("pstatus_id", {
            header: t("payment_conf"),
            enableSorting: true,
            cell: (info) => {
              const value = info.getValue();
              let color;
              switch (value) {
                case 0:
                  color = "#f44336";
                  break;
                case 1:
                case 2:
                  color = "#8bc34a";
                  break;
                default:
                  color = "#283853";
              }
              return (
                <Typography className="font-box" style={{ color }}>
                  {getStatusPayment(value)}
                </Typography>
              );
            },
          }),
        ];
      case "invitation confirmed":
        return [
          columnHelper.accessor("experiment_public_name", {
            header: t("experiment").replace(/<\/?[^>]+(>|$)/g, ""),
            cell: (info) => info.getValue(),
            enableSorting: true,
          }),
          columnHelper.accessor("session_name", {
            header: "Date",
            enableSorting: true,
            enableGrouping: false,
            cell: (info) => (
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Box className="font-box" fontSize={15} color={"#283853"}>
                  {info.getValue() ? extractDateTime(info.getValue()).date : "-"}
                </Box>
                <Box
                  className="font-box"
                  fontWeight={600}
                  fontSize={15}
                  color={"primary.500"}
                >
                  {info.getValue() ? extractDateTime(info.getValue()).time : '-'}
                </Box>
              </Box>
            ),
          }),
          columnHelper.accessor("lab_name", {
            header: t("location").replace(/<\/?[^>]+(>|$)/g, ""),
            enableSorting: true,
            enableGrouping: false, 
            cell: ({row}) => (
              <Box className="font-box" fontSize={15} color={"#283853"}>
                {row.original.experiment_type === "internet" ? "Online" : row.original.lab_name}
              </Box>
            )
          }),
          {
            id: "cancel",
            header: "",
            cell: ({ row }) => (
              <Button
                variant="contained"
                color="error"
                onClick={(event) => fc(row.original, event)}
                size="small"
              >
                Cancel
              </Button>
            ),
          },
        ];

      case "invitation":
      case "done":
        return [
          columnHelper.accessor("experiment_public_name", {
            header: t("experiment").replace(/<\/?[^>]+(>|$)/g, ""),
            cell: (info) => info.getValue(),
            enableSorting: true,
            enableGrouping: true,
          }),
          columnHelper.accessor("session_name", {
            header: t("date_and_time").replace(/<\/?[^>]+(>|$)/g, ""),
            enableSorting: true,
            enableGrouping: false,
            cell: (info) => (
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Box className="font-box" fontSize={15} color={"#283853"}>
                  {info.getValue() ? extractDateTime(info.getValue()).date : "-"}
                </Box>
                <Box
                  className="font-box"
                  fontWeight={600}
                  fontSize={15}
                  color={"#fbc6d5"}
                >
                  {info.getValue() ? extractDateTime(info.getValue()).time : "-"}
                </Box>
              </Box>
            ),
          }),
          columnHelper.accessor("lab_name", {
            header: t("location").replace(/<\/?[^>]+(>|$)/g, ""),
            enableSorting: true,
            enableGrouping: false, 
            cell: ({row}) => (
              <Box className="font-box" fontSize={15} color={"#283853"}>
                {row.original.experiment_type === "internet" ? "Online" : row.original.lab_name}
              </Box>
            )
          }),
          columnHelper.accessor("pstatus_id", {
            header: t("showup?").replace(/<\/?[^>]+(>|$)/g, ""),
            enableSorting: true,
            cell: (info) => {
              const value = info.getValue();
              let color;
              switch (value) {
                case 0:
                  color = "#ff9800";
                  break;
                case 1:
                case 2:
                  color = "#8bc34a";
                  break;
                default:
                  color = "#283853";
              }
              return (
                <Typography className="font-box" style={{ color }}>
                  {getStatusExperience(value)}
                </Typography>
              );
            },
          }),
        ];

      default:
        return [];
    }
  }, [type, fc]);

  return columns;
};

export { useColumnsType };
