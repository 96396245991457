import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";

export const getExperiencePayment = createAsyncThunk(
    "experience/getPayment",
    async (credentials, thunkAPI) => {
      try {
          const { data } = await axiosInstance.get("/participant/confirmation");

  
        return data ;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );
  