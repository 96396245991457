function extractDateTime(input) {
    const formattedInput = input?.replace('&nbsp;', ' ');

    const [date, time] = formattedInput?.split(' ');
    return {
        date: date,           
        time: time,          
      
    };
}


export {extractDateTime}