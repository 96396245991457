import { Box, Container, Paper, Typography, useTheme } from "@mui/material";
import React from "react";
import useExperienceData from "../../hooks/useExperienceData";
import Loading from "../other/Loading";
import Experiences from "./Experiences";
import Invitation from "./Invitation";
import { useTranslation } from "react-i18next";

const ExperienceDashboard = () => {
  const theme = useTheme();
  const {
    doneExperiences,
    experienceInvitations,
    experienceRegistrations,
    confirmationExperiences,
    loading,
    error,
  } = useExperienceData();
  const { t } = useTranslation();


  if (loading) {
    localStorage.setItem('selectedTab', 1);
    return <Loading />;
  }

  if (
    !experienceRegistrations.length &&
    !experienceInvitations.length &&
    !confirmationExperiences.length &&
    !doneExperiences.length
  ) {
    return null;  
  }
  
  return (
    <Container
      sx={{
        "@media (max-width: 600px)": {
          padding: 1,
        },
      }}
    >
      <Box flexDirection={"column"} display={"flex"} gap={3}>
        {experienceRegistrations.length > 0 && (
          <Paper
            sx={{
              marginBottom: 2,
              padding: 3,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: 'secondary.200',
              "@media (max-width: 600px)": {
                padding: 0,
              },
            }}
          >
            <Typography variant="h6" gutterBottom fontWeight={550}>
              {t("experiments_already_registered_for")}
            </Typography>
            <Experiences
              data={experienceRegistrations}
              type={"invitation confirmed"}
            />
          </Paper>
        )}

        {experienceInvitations.length > 0 && (
          <Paper
            sx={{
              marginBottom: 2,
              padding: 3,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: 'secondary.200',
              "@media (max-width: 600px)": {
                padding: 0,
              },
            }}
          >
            <Typography variant="h6" gutterBottom fontWeight={550}>
              {t("experiments_you_are_invited_for")}
            </Typography>
            <Invitation data={experienceInvitations} />
          </Paper>
        )}

        {confirmationExperiences.length > 0 && (
          <Paper
            sx={{
              marginBottom: 2,
              padding: 3,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: 'secondary.200',
              "@media (max-width: 600px)": {
                padding: 0,
              },
            }}
          >
            <Typography variant="h6" gutterBottom fontWeight={550}>
              {t("experiments_paid_confirmation")}
            </Typography>
            <Experiences data={confirmationExperiences} type={"payment"} />
          </Paper>
        )}

        {doneExperiences.length > 0 && (
          <Paper
            sx={{
              marginBottom: 2,
              padding: 3,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: 'secondary.200',
            }}
          >
            <Typography fontWeight={550} variant="h6" gutterBottom>
              {t("Expériences auxquelles vous avez participé·e")}
            </Typography>
            <Experiences data={doneExperiences} type={"done"} />
          </Paper>
        )}
      </Box>
      <Box marginBottom={5} />
    </Container>
  );
};

export default ExperienceDashboard;
