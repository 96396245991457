import { useMediaQuery } from "@mui/material";
import React from "react";
import { theme } from "../../theme/theme";
import ProfileDashboardWeb from "./web/ProfileDashboardWeb";

const ProfileManager = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return <ProfileDashboardWeb/>;
};

export default ProfileManager;
