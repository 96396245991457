import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useLogin from "../../hooks/useLogin";
import { setLanguage } from "../../store/slice/collectionsSlice";
import { logout } from "../../store/slice/userSlice";
import i18n from "../../translation/languageModule";
import { useTranslation } from "react-i18next";


const languages = [
  { code: "en", label: "English" },
  { code: "fr", label: "Français" },
  { code: "de", label: "Deutsch" },
];

function MobileHeader() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const darkMode = useSelector((state) => state.collections.dark);
  const { handleLogin } = useLogin();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {t} = useTranslation()
  const pages = [
    { label: t("menu_rules"), url: "rules&policies" },
    { label: t("menu_help"), url: "help" },
  ];
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const changeLanguage = (code) => {
    dispatch(setLanguage(code));
    i18n.changeLanguage(code);
    setDrawerOpen(false); 
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    setDrawerOpen(false);
  };

  const handleNavigation = (page) => {
    navigate(`/${page}`);

    setDrawerOpen(false); 
  };

  return (
    <AppBar position="sticky" sx={{ background: theme.palette.gradient.main }}>
      <Container maxWidth="xl">
        <Toolbar
          disableGutters={true}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6" noWrap component="div">
            <img
              src={`/logo-labex-small.png`}
              alt="MyApp Logo"
              style={{ height: "60px",marginTop:5, cursor: "pointer" }} 
              onClick={() => navigate(process.env.REACT_APP_PREFIX+"/")} 
            />
          </Typography>


          {!isAuthenticated && (
            <Button
              color="inherit"
              onClick={handleLogin}
              sx={{
                backgroundColor: "white", 
                color: "primary.100", 
                borderRadius: "20px", 
                padding: "6px 16px", 
                fontWeight: "bold", 
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", 
                "&:hover": {
                    backgroundColor: "primary.500",
                    color: "text.primary",
                  },
                marginRight: 2,
              }}
            >
              Login
            </Button>
          )}

          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { sm: "none" } }} 
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                width: "100%",
                background: theme.palette.gradient.main,
              }, 
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                p: 2,
              }}
            >
              <IconButton
                onClick={toggleDrawer(false)}
                edge="start"
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider />

            <List>
              {pages.map((page, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleNavigation(page.url)} 
                >
                  <ListItemText primary={page.label} />
                </ListItem>
              ))}

              <Divider />

              {languages.map((lang, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => changeLanguage(lang.code)}
                >
                  <ListItemText primary={lang.label} />
                </ListItem>
              ))}

              <Divider />

              {!isAuthenticated ? (
                <ListItem
                  button
                  onClick={() => {
                    handleLogin();
                    setDrawerOpen(false); 
                  }}
                >
                  <ListItemText primary="Login" />
                </ListItem>
              ) : (
                <ListItem
                  button
                  onClick={handleLogout}
                  sx={{ color: "primary.100" }} 
                >
                  <FiLogOut size={20} />
                  <ListItemText primary="Logout" sx={{ marginLeft: 1 }} />
                </ListItem>
              )}
            </List>
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default MobileHeader;
