import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Subbar from "../navigation/Subbar";
import { checkUrl } from "../utils/checkUrl";
import { extractRoomUrl } from "../utils/extractRoomUrl";
import { extractDateTime } from "../utils/extractDateTime";
import useExperienceData from "../../hooks/useExperienceData";
import { useLocationRoom } from "../../hooks/useLocationRoom";
import { useThunk } from "../../hooks/useThunk";
import { getTranslations } from "../../store/thunks/collections/getTranslations";
import Loading from "../other/Loading";
import { getStatusExperience } from "../utils/getStatus";

import "../../style/Map.css";
const retrieveArrayExperience = (type) => {
  switch (type) {
    case "done":
      return "doneExperiences";
    case "invitation confirmed":
      return "experienceRegistrations";
    case "payment":
      return "confirmationExperiences";

    default:
      return "doneExperiences";
  }
};

const ExperienceInformation = ({ data }) => {
  const { id } = useParams();
  const location = useLocation();
  const { type } = useLocation().state;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    doneExperiences,
    experienceInvitations,
    experienceRegistrations,
    confirmationExperiences,
    loading,
    error,
  } = useExperienceData();
  const experiences = useSelector((state) => state.experience);
  const experience = experiences[retrieveArrayExperience(type)].find((exp) => {
    return exp.experiment_id.toString() === id;
  });

  const {
    loading: fieldLoading,
    fields,
    lang,
  } = useSelector((state) => state.collections);

  

  const url = checkUrl(location);

  if (loading) {
    return <Loading />;
  }
  if (!experience) {
    return <Typography variant="h6">Experience not found</Typography>;
  }
  return (
    <Box padding={2}>
      <Subbar url={url} id={experience?.experiment_public_name} />
      <Box sx={{ padding: 2 }}>
        <Paper sx={{ padding: 3 }}>
          <Typography variant="h3" gutterBottom>
            Experience Information
          </Typography>
          <Box sx={{ marginTop: 5 }} />
          <Box
            display="grid"
            gridTemplateColumns={isSmallScreen ? "1fr" : "1fr 1fr"}
            gap={2}
          >
            {/* Left Column */}
            <Box>
              <Paper
                sx={{
                  padding: 2,
                  marginBottom: 3,
                  backgroundColor: "#e3f2fd",
                  borderRadius: 3,
                }}
              >
                <Typography variant="h6">Experience</Typography>
                <Typography>{experience?.experiment_public_name}</Typography>
              </Paper>
              <Paper
                sx={{
                  padding: 2,
                  marginBottom: 3,
                  backgroundColor: "#e3f2fd",
                  borderRadius: 3,
                }}
              >
                <Typography variant="h6">Date</Typography>
                <Typography color={theme.palette.primary['500']}>
                  {experience && `${extractDateTime(experience?.session_name).date} - ${
                    extractDateTime(experience?.session_name).time
                  }`}
                </Typography>
              </Paper>

              <Paper
                sx={{
                  padding: 2,
                  marginBottom: 3,
                  backgroundColor: "#e3f2fd",
                  borderRadius: 3,
                }}
              >
                <Typography variant="h6">Location</Typography>
                <Typography>{experience.lab_name || "N/A"}</Typography>
              </Paper>
              <Paper
                sx={{
                  padding: 2,
                  marginBottom: 3,
                  backgroundColor: "#e3f2fd",
                  borderRadius: 3,
                }}
              >
                <Typography variant="h6">Status</Typography>
                <Typography
                  sx={{ color: getStatusColor(experience?.pstatus_id) }}
                >
                  {getStatusExperience(experience?.pstatus_id)}
                </Typography>
              </Paper>
            </Box>

            {/* Right Column with conditional iframe */}
            {extractRoomUrl(experience?.lab_name, fields, lang)?.url ? (
              <Box minHeight={400}>
                <iframe
                  src={extractRoomUrl(experience?.lab_name, fields, lang)?.url}
                  style={{
                    border: "none",
                    width: "100%",
                    height: isSmallScreen ? "50vh" : "100%",
                  }}
                  title="Experience Location"
                />
              </Box>
            ) : (
              <Typography variant="h6" textAlign="center">
                Map not available for this location
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 0:
      return "#ff9800";
    case 1:
      return "#8bc34a";
    case "Cancelled":
      return "#f44336";
    default:
      return "#283853";
  }
};

export default ExperienceInformation;
