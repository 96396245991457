import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";

export const getExperienceInvitation = createAsyncThunk(
  "experience/invited",
  async (credentials, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get("/participant/invited");

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
