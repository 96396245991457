import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";

export const getExperienceRegistration = createAsyncThunk(
    "experience/registered",
    async (credentials, thunkAPI) => {
      try {
          const { data } = await axiosInstance.get("/participant/registered");
  
  
        return data ;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );
  