function extracDeadLineTime(
    unixTimestamp
) {
    const date = new Date(unixTimestamp * 1000);
    
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const year = date.getUTCFullYear();
    
    return `${day}-${month}-${year}`;
}

export { extracDeadLineTime };
