import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useThunk } from './useThunk';
import { setAuth } from '../store/slice/userSlice';
import { useDispatch } from 'react-redux';
import axiosInstance from '../api/axiosInstance';
import { checkSession } from '../store/thunks/user/checkSession';

const useLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [runCheckSession, isLoadingSession, errorSession] =
    useThunk(checkSession);

  const handleLogin = () => {
    const link_to_switch_login = "https://login.eduid.ch/idp/profile/SAML2/Unsolicited/SSO";
    const providerParam = '?providerId=';
    const https_hostname = encodeURIComponent('https://labex.unil.ch');
    const shibboleth = encodeURIComponent('/shibboleth');
    const providerUrlSection = providerParam + https_hostname + shibboleth;
    const targetParam = '&target=';
    const targetUrlSection = targetParam + https_hostname + getTargetUrl();
    const urlToLogin = link_to_switch_login + providerUrlSection + targetUrlSection;

    window.location.href = urlToLogin;
  };
  
  const getTargetUrl = () => {
    const prodTarget = encodeURIComponent('/redirect/switcheduprod.php');
    const testTarget = encodeURIComponent('/redirect/switchedutest.php');
    const switchToORSEELocal = encodeURIComponent('/redirect/switchedulocal.php');
    switch (window.location.host) {
      case 'labex.unil.ch':
        return prodTarget;
      case 'experiments-hec.unil.ch':
        return testTarget;
      default:
        return switchToORSEELocal;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    if (token) {
      localStorage.setItem('jwtToken', token);

      urlParams.delete('token');
      runCheckSession()
      localStorage.setItem("selectedTab", 0);
      navigate('/orsee');
    } else {
      console.error('Token not found in URL');
    }
  }, [navigate, location]);

  return { handleLogin};
};

export default useLogin;
