import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import { setOtherInfo } from "../../slice/userSlice";

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (credentials, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get("/participant/profile");

      const userObject = {
        locked: data.locked,
        last_login_attempt: data.last_login_attempt,
        failed_login_attempts: data.failed_login_attempts,
        creation_time: data.creation_time,
        deletion_time: data.deletion_time,
        subpool_id: data.subpool_id,
        subscriptions: data.subscriptions,
        rules_signed: data.rules_signed,
        status_id: data.status_id,
        number_reg: data.number_reg,
        number_noshowup: data.number_noshowup,
        last_enrolment: data.last_enrolment,
        last_profile_update: data.last_profile_update,
        last_activity: data.last_activity,
        language: data.language,
        email: data.email,
        phone_number: data.phone_number,
        lname: data.lname,
        fname: data.fname,
        begin_of_studies: data.begin_of_studies,
        field_of_studies: data.field_of_studies,
        profession: data.profession,
        gender: data.gender,
        mother_tongue: data.mother_tongue,
        institution: data.institution,
        other_language: data.other_language,
        date_of_birth: data.date_of_birth,
        payment_amount: data.payment_amount,
        account_holder_fname: data.account_holder_fname,
        account_holder_lname: data.account_holder_lname,
        street_name: data.street_name,
        city: data.city,
        postal_code: data.postal_code,
        country: data.country,
        iban: data.iban,
        swift: data.swift, 
        bank_clearing: data.bank_clearing,
        country_of_bank: data.country_of_bank,
        account_number: data.account_number,
        is_account_holder_same_name: data.is_account_holder_same_name,
      };

      const transformedData = [
        {
          mother_tongue: data.mother_tongue || "",
          other_language: data.other_language || "",
          labInvites: data.labInvites || "",
          onlineExperiences: data.onlineExperiences || "",
          gender: data.gender || "",
          institution: data.institution || "",
          field_of_studies: data.field_of_studies || "",
          begin_of_studies: data.begin_of_studies || "",
        },
        {
          street_name: data.street_name || "",
          postal_code: data.postal_code || "",
          city: data.city || "",
          country: data.country || "",
        },
        {
          account_holder_fname: data.account_holder_fname || "",
          account_holder_lname: data.account_holder_lname || "",
          country_of_bank: data.country_of_bank || "",
          iban: data.iban || "",
          account_number: data.account_number || "",
          swift: data.swift || "",
          bank_clearing: data.bank_clearing || "",
          is_account_holder_same_name: data.is_account_holder_same_name || "",
        },
        {
          fname: data.fname || "",
          lname: data.lname || "",
          email: data.email || "",
          date_of_birth: data.date_of_birth || "",
          phone_number: data.phone_number || "",
        },
        userObject
      ];


      return transformedData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
