const extractRoomUrl = (labName, fields, lang) => {
  const localLabFields = fields.filter(
    (field) => field.content_type === "local_lab"
  );
  const roomCodeMatch = labName.match(/\b\d{1,3}\.\d{1,3}\b/);


  if (!fields.length || labName === "online" || labName.includes("EPFL")) {
    return { url: "" };
  }

  if (roomCodeMatch) {
    const roomCode = roomCodeMatch[0];

    const matchingField = localLabFields.find((field) =>
      field[lang].includes(roomCode)
    );

    if (matchingField) {
      return { url: `https://planete.unil.ch/?local=${matchingField[lang]}` };
    }
  }
};

export { extractRoomUrl };
