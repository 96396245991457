import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Policies from "./Policies";
import Rules from "./Rules";
import { useTheme } from "@emotion/react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function RulesManager() {
  const [value, setValue] = useState(0);

  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="rules and policies tabs"
        >
          <Tab label="Rules" {...a11yProps(0)} sx={{color: theme.palette.text.primary}}/>
          <Tab label="Policies" {...a11yProps(1)} sx={{color: theme.palette.text.primary}}/>
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Rules />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Policies />
      </TabPanel>
    </Container>
  );
}
