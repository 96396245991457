import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";

export const getExperienceDone = createAsyncThunk(
  "experience/participated",
  async (credentials, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get("/participant/participated");
      
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
