import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import i18n from "../../../translation/languageModule";

export const getTranslations = createAsyncThunk(
  "collections/getTranslations",
  async (credentials, thunkAPI) => {
    try {
      const { lang ,fields} = thunkAPI.getState().collections;
      let { data } = await axiosInstance.get(`/${lang}`);
      data = JSON.parse(data);
      const transformed = data.reduce((acc, item) => {
        

        if (item.content_type === "faq_question") {
          acc[`faq${item.content_name}`] = item[lang];
        } else if (item.content_type === "faq_answer") {
          acc[`answer${item.content_name}`] = item[lang];
        } else {
          acc[item.content_name] = item[lang];
        }

        return acc;
      }, {});

      i18n.addResources(lang, "translation", transformed);
      i18n.changeLanguage(lang);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
