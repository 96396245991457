import { createSlice } from "@reduxjs/toolkit";
import { login } from "../thunks/user/login";
import { getProfile } from "../thunks/user/getProfile";
import { checkSession } from "../thunks/user/checkSession";


const initialState = {
  isAuthenticated: false,
  userInfo: [],
  userOthersInfo:null,
  loading: false,
  error: null,
}
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setOtherInfo: (state, action) => {
      state.userOthersInfo = action.payload;
    },
    logout: (state, action) => {
      localStorage.removeItem("jwtToken");
      state.isAuthenticated = false
      state.userInfo=[]
            
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state,action) => {
        state.loading = false;
        state.userInfo = action.payload
      })
      .addCase(getProfile.rejected, (state,action) => {
        state.isAuthenticated = false
        state.userInfo=[]
      })
      .addCase(checkSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkSession.fulfilled, (state,action) => {
        state.loading = false;
        state.isAuthenticated = true
      })
      .addCase(checkSession.rejected, (state,action) => {
        
        state.isAuthenticated = false
        state.userInfo=[]
      })
      
  },
});


export const { setOtherInfo,setAuth,logout } = userSlice.actions;

export default userSlice.reducer;
