import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Stepper_ = ({
  steps,
  activeStep,
  skipped,
  handleBack,
  handleNext,
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const navigate = useNavigate();



  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  useEffect(() => {
    if (activeStep === steps.length) {
      const timer = setTimeout(() => {
        navigate("/orsee");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [activeStep, steps.length, navigate]);

  return (
    <Box sx={{ width: "100%" }}>
      {!isMobile && (
        <Stepper
          activeStep={activeStep}
          orientation={!isMobile ? "horizontal" : "vertical"}
        >
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (step.skippable) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel
                  {...labelProps}
                  sx={{
                    "& .MuiStepLabel-label": { color: "text.primary" },
                    "& .Mui-active": { color: "primary.main" },
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      {isMobile && (
        <Typography variant="h4">
          {activeStep + 1} / {steps.length} - {steps[activeStep].label}
        </Typography>
      )}

      {children}
      {activeStep !== steps.length && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: isMobile ? 10 : 3,
          }}
        >
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            <Typography fontSize={"1.2em"}>{t("enroll_back")}</Typography>
          </Button>

          <Button onClick={handleNext}>
            <Typography fontSize={"1.2em"}>
              {activeStep === steps.length - 1
                ? t("enroll_finish")
                : t("enroll_next")}
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Stepper_;
