const handleLogin = () => {
  window.location.href = getEduIDUrl();
};

const getTargetUrl = (enroll = false) => {
  const prodTarget = encodeURIComponent("/api/index.php/edu/login");
  const testTarget = encodeURIComponent("/api/index.php/edu/login");
  const switchToORSEELocal = encodeURIComponent("/redirect/switchedulocal.php");
  const orseeTest = encodeURIComponent("/redirect/switchedutest.php");
  const orseeProd = encodeURIComponent("/redirect/switcheduprod.php");

  switch (window.location.host) {
    case "research-platform.unil.ch":
      return prodTarget;
    case "rp-test.unil.ch":
      return testTarget;
    case "experiments-hec.unil.ch":
      return orseeTest;
    case "labex.unil.ch":
      return orseeProd;
  }
  return switchToORSEELocal;
};

const getEduIDUrl = () => {
  const link_to_switch_login =
    "https://login.eduid.ch/idp/profile/SAML2/Unsolicited/SSO";
  const providerParam = "?providerId=";
  const https_hostname = encodeURIComponent("https://labex.unil.ch");
  const shibboleth = encodeURIComponent("/shibboleth");
  const providerUrlSection = providerParam + https_hostname + shibboleth;
  const targetParam = "&target=";
  const targetUrlSection = targetParam + https_hostname + getTargetUrl();
  const urlToEduID =
    link_to_switch_login + providerUrlSection + targetUrlSection;

  return urlToEduID;
};

export { handleLogin, getEduIDUrl };
