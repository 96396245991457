import {
  Badge,
  Box,
  Container,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useExperienceData from "../../../hooks/useExperienceData";
import Loading from "../../other/Loading";
import Experiences from "../Experiences";
import Invitation from "../Invitation";

const ExperienceDashboard = () => {
  const {
    doneExperiences,
    experienceInvitations,
    experienceRegistrations,
    loading,
    error,
  } = useExperienceData();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");

  if (loading) {
    return <Loading />;
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (
    !experienceRegistrations.length &&
    !experienceInvitations.length &&
    !experienceRegistrations.length &&
    !doneExperiences.length
  ) {
    return (
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ fontSize: { xs: "1.1rem", sm: "1.25rem" } }}
        >
          No experiences available.
        </Typography>
      </Container>
    );
  }

  const tabOrder = [
    {
      label: t("menu_invitation"),
      count: experienceInvitations.length,
      condition: experienceInvitations.length >= 0,
      component: <Invitation data={experienceInvitations} />,
    },
    {
      label: t("menu_comfirmed"),
      count: 0,
      condition: experienceRegistrations.length >= 0,
      component: experienceRegistrations.length ? (
        <Experiences
          data={experienceRegistrations}
          type={"invitation confirmed"}
        />
      ) : (
        <Container
          sx={{
            minHeight: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ fontSize: { xs: "1.1rem", sm: "1.25rem" } }}
          >
            No experiences available.
          </Typography>
        </Container>
      ),
    },
    {
      label: t("menu_completed"),
      count: 0,
      condition: doneExperiences.length >= 0,
      component: <Experiences data={doneExperiences} type={"done"} />,
    },
  ];

  const availableTabs = tabOrder.filter(
    (tab) => tab.condition || tab.label === t("menu_completed")
  );

  return (
    <Container
      sx={{
        padding: { xs: 1, sm: 3 },
        maxWidth: "1000px",
        margin: "0 auto",
      }}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant={isMobile ? "fullWidth" : "standard"}
          centered={!isMobile}
          sx={{
            marginBottom: 3,
            borderRadius: 2,
            padding: isMobile ? 1 : 2,
          }}
        >
          {availableTabs.map((tab, index) => (
            <Tab
              key={index}
              label={
                <Typography
                  color="primary"
                  sx={{
                    fontSize: { xs: "0.72rem", sm: "1rem" },
                  }}
                >
                  {tab.label}
                </Typography>
              }
            />
          ))}
        </Tabs>

        {availableTabs[activeTab] && <>{availableTabs[activeTab].component}</>}
      </Box>
      <Box marginBottom={5} />
    </Container>
  );
};

export default ExperienceDashboard;
