import { ThemeProvider } from "@emotion/react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ExperienceDashboard from "./components/experience/ExperienceDashboard";
import ExperienceInformation from "./components/experience/ExperienceInformation";
import ExperienceDashboardMobile from "./components/experience/mobile/ExperienceDashboardMobile";
import PaymentExperience from "./components/experience/mobile/PaymentExperience";
import FAQ from "./components/help/FAQ";
import RulesManager from "./components/help/RulesManager";
import LandingPage from "./components/login/LandingPage";
import Layout from "./components/navigation/Layout";
import ProfileManager from "./components/profile/ProfileManager";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import { useThunk } from "./hooks/useThunk";
import { getTranslations } from "./store/thunks/collections/getTranslations";
import { checkSession } from "./store/thunks/user/checkSession";
import { getProfile } from "./store/thunks/user/getProfile";

import EnrollPage from "./components/login/EnrollPage";
import { darkTheme, theme } from "./theme/theme";
import i18n from "./translation/languageModule";

function App() {
  const isInitialized = useRef(false);

  const [runGetProfile, isLoading, errorProfile] = useThunk(getProfile);
  const [runGetTranslations, isLoadingField, errorField] =
    useThunk(getTranslations);
  const [runCheckSession, isLoadingSession, errorSession] =
    useThunk(checkSession);

  const { userInfo, isAuthenticated, loading } = useSelector(
    (state) => state.user
  );

  const fields = useSelector((state) => state.collections.fields);
  const { lang, darkMode } = useSelector((state) => state.collections);
  const previousLang = useRef(lang);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (
      !isInitialized.current &&
      userInfo.length === 0 &&
      !isLoading &&
      isAuthenticated
    ) {
      runGetProfile();
      isInitialized.current = true;
    }
  }, [runGetProfile, userInfo.length, isAuthenticated]);

  useEffect(() => {
    if (previousLang.current !== lang && !isLoadingField && fields.length > 0) {
      runGetTranslations();
      previousLang.current = lang;
    } else if (!fields.length) {
      runGetTranslations();
    }
  }, [lang, isLoadingField, fields.length]);

  useEffect(() => {
    if (fields.length && !isLoadingField) {
      const transformed = fields.reduce((acc, item) => {
        if (item.content_type === "faq_question") {
          acc[`faq${item.content_name}`] = item[lang];
        } else if (item.content_type === "faq_answer") {
          acc[`answer${item.content_name}`] = item[lang];
        } else {
          acc[item.content_name] = item[lang];
        }

        return acc;
      }, {});
      i18n.addResources(lang, "translation", transformed);
      i18n.changeLanguage(lang);
    }
  }, []);

  useEffect(() => {
    setInterval(() => runCheckSession(), parseInt(60 * 60 * 1000));
  }, []);

  if (isLoadingField || loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          background:
            "linear-gradient(90deg, rgba(13,36,64,0.8) 0%, rgba(59,91,140,0.8) 43%, rgba(244,153,179,0.8) 100%)",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={darkMode ? darkTheme : theme}>
      <Router>
        <Layout>
          <Routes>
            {!isAuthenticated && (
              <Route
                path={"/" + process.env.REACT_APP_PREFIX}
                element={<LandingPage />}
              />
            )}
            {!isAuthenticated && <Route path="/" element={<LandingPage />} />}
            {!isAuthenticated && (
              <Route
                path={process.env.REACT_APP_PREFIX + "/enroll"}
                element={<EnrollPage />}
              />
            )}
            <Route
              path={process.env.REACT_APP_PREFIX + "/rules&policies"}
              element={<RulesManager />}
            />
            <Route
              path={process.env.REACT_APP_PREFIX + "/help"}
              element={<FAQ />}
            />
            <Route element={<ProtectedRoute />}>
              {isMobile && (
                <Route
                  path={process.env.REACT_APP_PREFIX + "/payment"}
                  element={<PaymentExperience />}
                />
              )}
              {isMobile && (
                <Route
                  path={process.env.REACT_APP_PREFIX + "/"}
                  element={<ExperienceDashboardMobile />}
                />
              )}
              {isMobile && (
                <Route
                  path={process.env.REACT_APP_PREFIX + "/experience/:id"}
                  element={<ExperienceInformation />}
                />
              )}
              {!isMobile && (
                <Route
                  path={"/" + process.env.REACT_APP_PREFIX}
                  element={<ExperienceDashboard />}
                />
              )}
              <Route
                path={process.env.REACT_APP_PREFIX + "/profile"}
                element={<ProfileManager />}
              />
            </Route>
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
