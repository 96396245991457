import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { extractDateTime } from "../utils/extractDateTime";
import { extracDeadLineTime } from "../utils/extractDeadLineTime";
import InscriptionDialog from "./InscriptionDialog";
import LocationDialog from "./LocationDialog";
import { capitalizeFirstLetter } from "../utils/capitalizerLetter";

const notAllowed = ["online"];

const Invitation = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openLocation, setOpenLocation] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const handleClickOpen = (experience) => {
    setSelectedExperience(experience);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedExperience(null);
  };

  const handleConfirm = async () => {
    handleClose();
  };

  const handleClickOpenLocation = (location) => {
    setSelectedLocation(location);
    setOpenLocation(true);
  };

  const handleCloseLocation = () => {
    setOpenLocation(false);
    setSelectedLocation(null);
  };

  return (
    <Box sx={{ padding: isMobile ? 1 : 2 }}>
      <Paper sx={{ padding: isMobile ? 1 : 2 }}>
        <List>
          {data.map((exp, index) => {
            const containsNotAllowed = notAllowed.some((word) =>
              exp.lab_name.includes(word)
            );
            return (
              <React.Fragment key={exp.id}>
                <ListItem
                  sx={{
                    marginBottom: 2,
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "flex-start" : "center",
                    paddingBottom: isMobile ? 2 : 0,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        fontSize: isMobile ? "1rem" : "1.25rem",
                      }}
                    >
                      {capitalizeFirstLetter(exp.experiment_public_name)}
                    </Typography>
                    <Grid container spacing={isMobile ? 1 : 2}>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          style={{ fontWeight: 600 }}
                          variant="body1"
                          sx={{ fontSize: isMobile ? "0.9rem" : "1rem" }}
                        >
                          <strong>
                            {capitalizeFirstLetter(t("registration_until"))}:{" "}
                          </strong>
                          {extracDeadLineTime(exp.registration_unixtime)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: isMobile ? "0.9rem" : "1rem" }}
                        >
                          <strong>
                            {capitalizeFirstLetter(
                              t("date_and_time").replace(/<\/?[^>]+(>|$)/g, "")
                            )}
                          </strong>{" "}
                          {`${extractDateTime(exp.session_name).date} - ${
                            extractDateTime(exp.session_name).time
                          }`}
                        </Typography>
                        <Box
                          variant="body1"
                          display={"flex"}
                          alignItems="center"
                          sx={{ fontSize: isMobile ? "0.9rem" : "1rem" }}
                        >
                          <strong>
                            {capitalizeFirstLetter(
                              t("location").replace(/<\/?[^>]+(>|$)/g, "")
                            )}
                            :{" "}
                          </strong>{" "}
                          <Typography
                            onClick={() => {
                              containsNotAllowed &&
                                handleClickOpenLocation(exp.lab_name);
                            }}
                            sx={{
                              cursor: containsNotAllowed ? "pointer" : "default",
                              textDecoration: containsNotAllowed
                                ? "underline"
                                : "none",
                              color: containsNotAllowed
                                ? theme.palette.primary.main
                                : "inherit",
                              marginLeft: 1,
                              fontSize: isMobile ? "0.9rem" : "1rem",
                            }}
                          >
                            {exp.lab_name}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: isMobile ? "flex-start" : "flex-end",
                      width: isMobile ? "100%" : "auto",
                      marginTop: isMobile ? 2 : 0,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: isMobile ? 12 : 14,
                        padding: isMobile ? "5px 10px" : "8px 16px",
                      }}
                      onClick={() => handleClickOpen(exp)}
                    >
                      {t("register")}
                    </Button>
                  </Box>
                </ListItem>
                {index !== data.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
        </List>
      </Paper>

      <InscriptionDialog
        open={open}
        onClose={handleClose}
        experience={selectedExperience}
        onConfirm={handleConfirm}
        type={"confirm"}
      />
      <LocationDialog
        open={openLocation}
        onClose={handleCloseLocation}
        location={selectedLocation}
      />
    </Box>
  );
};

export default Invitation;
