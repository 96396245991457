import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useState } from "react";
import { FaArrowUp } from "react-icons/fa6";
import { LuGroup, LuUngroup } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme/theme";
import InscriptionDialog from "./InscriptionDialog";
import { useColumnsType } from "../../hooks/useColumnsTypes";
import LocationDialog from "./LocationDialog";

const noAvailableUrl = ["Epfl", "online", "epfl", "Online"];

const Experiences = ({ data, type }) => {
  const [grouping, setGrouping] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [expanded, setExpanded] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openLocation, setOpenLocation] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = (experience, event) => {
    event.stopPropagation();
    setSelectedExperience(experience);
    setOpenDialog(true);
  };
  const columns = useColumnsType(type, handleCancel);
  const table = useReactTable({
    data,
    columns,
    state: {
      grouping,
      sorting,
      expanded,
    },
    onGroupingChange: (newGrouping) => {
      if (newGrouping.length > 1) {
        setGrouping([newGrouping[1]]);
      } else {
        setGrouping(newGrouping);
      }
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      expanded,
    },
  });

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedExperience(null);
  };

  const handleConfirm = () => {
    console.log(
      `Confirmed inscription for experience ID: ${selectedExperience.experiment_id}`
    );
    handleClose();
  };

  const handleClickOpenLocation = ({ original }) => {
    setSelectedLocation(original.lab_name);
    setOpenLocation(true);
  };

  const handleCloseLocation = () => {
    setOpenLocation(false);
    setSelectedLocation(null);
  };

  return (
    <Box
      component="paper"
      sx={{
        padding: 2,
        "@media (max-width: 600px)": {
          padding: 0,
        },
      }}
    >
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 3, minHeight: isMobile ? 300 : "" }}
      >
        <Table
          sx={{
            "@media (max-width: 600px)": {
              "& th, & td, & .font-box": {
                fontSize: "10px",
              },
              "& button": {
                padding: "2px",
                marginRight: "4px",
              },
              "& svg": {
                fontSize: "16px",
              },
            },
          }}
        >
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "secondary.300",
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {header.column.getCanGroup() && (
                          <Button
                            onClick={header.column.getToggleGroupingHandler()}
                            sx={{
                              minWidth: 0,
                              padding: "4px",
                              marginRight: "8px",
                            }}
                          >
                            {header.column.getIsGrouped() ? (
                              <LuGroup size={20} />
                            ) : (
                              <LuUngroup size={20} />
                            )}
                          </Button>
                        )}
                        <TableSortLabel
                          sx={(theme) => ({
                            fontWeight: "bold",
                            backgroundColor: "secondary.300",
                          })}
                          active={!!header.column.getIsSorted()}
                          direction={
                            header.column.getIsSorted()
                              ? header.column.getIsSorted()
                              : "asc"
                          }
                          IconComponent={(props) => (
                            <FaArrowUp
                              {...props}
                              style={{ color: theme.palette.action.hover }}
                            />
                          )}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            <Typography
                              sx={{
                                "&:hover": {
                                  color: theme.palette.action.hover,
                                },
                                color: "white",
                                fontSize: { xs: "1em", sm: "1em",md:"1,2em" },
                              }}
                            >
                              {header.column.columnDef.header}
                            </Typography>,
                            header.getContext()
                          )}
                        </TableSortLabel>
                      </Box>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                onClick={
                  !row.getIsGrouped() &&
                  type !== "payment" &&
                  !noAvailableUrl.some((url) =>
                    row?.original?.lab_name
                      ?.toLowerCase()
                      .includes(url.toLowerCase())
                  )
                    ? () => handleClickOpenLocation(row)
                    : undefined
                }
                sx={{
                  cursor:
                    !row.getIsGrouped() &&
                    type !== "payment" &&
                    !noAvailableUrl.some((url) =>
                      row?.original?.lab_name
                        ?.toLowerCase()
                        .includes(url.toLowerCase())
                    )
                      ? "pointer"
                      : "default",
                  "&:hover td": {
                    backgroundColor: !row.getIsGrouped() && "secondary.600",
                  },
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    sx={{ backgroundColor: "secondary.500" }}
                  >
                    {cell.getIsGrouped() ? (
                      <>
                        <Button
                          onClick={row.getToggleExpandedHandler()}
                          sx={{
                            minWidth: 0,
                            padding: "4px",
                            marginRight: "8px",
                          }}
                        >
                          {row.getIsExpanded() ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ChevronRightIcon />
                          )}
                        </Button>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}{" "}
                        ({row.subRows.length})
                      </>
                    ) : cell.getIsAggregated() ? (
                      flexRender(
                        cell.column.columnDef.aggregatedCell ??
                          cell.column.columnDef.cell,
                        cell.getContext()
                      )
                    ) : cell.getIsPlaceholder() ? null : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <InscriptionDialog
        open={openDialog}
        onClose={handleClose}
        experience={selectedExperience}
        onConfirm={handleConfirm}
      />
      <LocationDialog
        open={openLocation}
        onClose={handleCloseLocation}
        location={selectedLocation}
      />
    </Box>
  );
};

export default Experiences;
