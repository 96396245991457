import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { countries } from "../../utils/countryList";
import IBAN from "iban";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

const FormTextfield = styled(TextField)(({theme, inView}) => ({
  color: theme.palette.text.primary,
  "& label": {
    color: theme.palette.text.primary
  },
  "&:hover": {
    color: theme.palette.primary['500'],
    "& label": {
      color: theme.palette.primary['500']+ " !important"
    },
    "& fieldset": {
      borderColor: theme.palette.primary['500'] +" !important"
    }
  }
}))

const PaymentInformation = ({ data, setData, personalInfo, errors }) => {
  const [ibanError, setIbanError] = useState("");
  const { t } = useTranslation();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  
  useEffect(() => {
    if (data.is_account_holder_same_name === "y") {
      setData({
        ...data,
        account_holder_fname: personalInfo.fname,
        account_holder_lname: personalInfo.lname,
      });
    } else {
      setData({
        ...data,
        account_holder_fname: "",
        account_holder_lname: "",
      });
    }
  }, [data.is_account_holder_same_name]);

  const handleCountryChange = (event, value) => {
    if (value && value.code !== "CH") {
      setData({
        ...data,
        country_of_bank: value.code,
        swift: "",
        bank_clearing: "",
        account_number: "",
      });
    } else {
      setData({
        ...data,
        country_of_bank: value ? value.code : "",
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "iban") {
      const isValidIban = IBAN.isValid(value);
      setIbanError(isValidIban ? "" : "Invalid IBAN number");
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Container
      sx={{
        padding:!isMobile ? "20px" :3 ,

        backgroundColor: "table.tr",
        borderRadius: "8px",
        border: "1px solid #e0e0e0",
      }}
    >
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "20px" }}>
        {t("bank_transfer_explanation")
          .replace(/<[^>]*>/g, "")
          .trim()}
      </Typography>
      <Typography color={"#ef5350"} variant="body1" gutterBottom fontSize={15}>
        {t("banking_rules_unil")
          .replace(/<[^>]*>/g, "")
          .trim()}
      </Typography>
      <FormControl component="fieldset" sx={{ marginBottom: "20px" }}>
        <FormLabel component="legend">
          {t("is_account_holder_same_name")}
        </FormLabel>
        <RadioGroup
          row
          aria-label="is_account_holder_same_name"
          name="is_account_holder_same_name"
          value={data.is_account_holder_same_name ?? ""}
          onChange={handleInputChange}
        >
          <FormControlLabel
            value="y"
            control={<Radio sx={{color: theme.palette.text.primary}}/>}
            label={t("is_account_holder_same_name_yes")}
          />
          <FormControlLabel
            value="n"
            control={<Radio sx={{color: theme.palette.text.primary }}/>}
            label={t("is_account_holder_same_name_no")}
          />
        </RadioGroup>
      </FormControl>
      {data.is_account_holder_same_name === "n" && (
        <Box sx={{ marginBottom: 5 }}>
          <Typography variant="body1" gutterBottom color="#ef5350">
            {t("is_account_holder_same_name_info_no").replace(
              /<\/?[^>]+(>|$)/g,
              ""
            )}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="/files/procédure_procuration.pdf"
            download
            sx={{ textTransform: "none" }}
          >
            Download the document
          </Button>
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormTextfield
            label={t("account_holder_firstname")}
            variant="outlined"
            fullWidth
            name="account_holder_fname"
            disabled={data.is_account_holder_same_name === "y"}
            value={
              data.is_account_holder_same_name === "y"
                ? personalInfo.fname
                : data.account_holder_fname
            }
            onChange={handleInputChange}
            sx={{ marginBottom: "20px" }}
            error={Boolean(errors.account_holder_fname)}
            helperText={errors.account_holder_fname}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextfield
            label={t("account_holder_lastname")}
            disabled={data.is_account_holder_same_name === "y"}
            variant="outlined"
            fullWidth
            name="account_holder_lname"
            value={
              data.is_account_holder_same_name === "y"
                ? personalInfo.lname
                : data.account_holder_lname
            }
            onChange={handleInputChange}
            sx={{ marginBottom: "20px" }}
            error={Boolean(errors.account_holder_lname)}
            helperText={errors.account_holder_lname}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="country-select"
            options={countries}
            getOptionLabel={(option) => option.code}
            value={
              countries.find((c) => c.code === data.country_of_bank) || null
            }
            onChange={handleCountryChange}
            filterOptions={(options, state) =>
              options.filter(
                (option) =>
                  option.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase()) ||
                  option.code
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
              )
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <FormTextfield
                {...params}
                label={t("country_of_bank").split("<")[0]}
                margin="normal"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                }}
                error={Boolean(errors.country_of_bank)}
                helperText={errors.country_of_bank}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextfield
            label="IBAN"
            variant="outlined"
            fullWidth
            name="iban"
            value={data.iban}
            onChange={handleInputChange}
            error={Boolean(ibanError || errors.iban)}
            helperText={ibanError || errors.iban}
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
      </Grid>
      {data.country_of_bank !== "CH" && (
        <>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ marginTop: "20px", marginBottom: "20px" }}
            fontWeight={"bold"}
          >
            {t("account_number").match(/<b>(.*?)<\/b>/)[1].trim()}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormTextfield
                label={t("account_number")
                  .substring(t("account_number").lastIndexOf(")") + 1)
                  .trim()}
                variant="outlined"
                fullWidth
                name="account_number"
                value={data.account_number}
                onChange={handleInputChange}
                sx={{ marginBottom: "20px" }}
                error={Boolean(errors.account_number)}
                helperText={errors.account_number}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextfield
                label={t("swift")}
                variant="outlined"
                fullWidth
                name="swift"
                value={data.swift}
                onChange={handleInputChange}
                sx={{ marginBottom: "20px" }}
                error={Boolean(errors.swift)}
                helperText={errors.swift}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextfield
                label={t("bank_clearing")}
                variant="outlined"
                fullWidth
                name="bank_clearing"
                value={data.bank_clearing}
                onChange={handleInputChange}
                sx={{ marginBottom: "20px" }}
                error={Boolean(errors.bank_clearing)}
                helperText={errors.bank_clearing}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default PaymentInformation;
