import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { Link, styled } from "@mui/material";
import { useTheme } from "@emotion/react";

const FAQQuestion = styled(AccordionSummary)(({ theme, inView }) => ({
  backgroundColor: theme.palette.primary["500"],
  color: theme.palette.text.secondary,
  "& svg": {
    color: theme.palette.text.secondary,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary["100"],
    color: theme.palette.text.primary,
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
}));

function formatText(rawText) {
  let formattedText = rawText.replace(/<BR>/g, "\n");

  const paragraphs = formattedText
    .split("\n")
    .filter((paragraph) => paragraph.trim() !== "");

  const jsxElements = paragraphs.map((paragraph, index) => {
    const formattedParagraph = paragraph.replace(
      /<b>(.*?)<\/b>/g,
      "<strong>$1</strong>"
    );

    return (
      <p key={index} dangerouslySetInnerHTML={{ __html: formattedParagraph }} />
    );
  });

  return jsxElements;
}
export default function FAQ() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          {t("faq_long")}
        </Typography>
      </Box>
      <Divider sx={{ mb: 4 }} />
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("faq8")}</Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>{formatText(t("answer8"))}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            {t("faq1")}
          </Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>
            {formatText(t("answer1"))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>{t("faq1438784736")}</Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>
           {formatText(t("answer1438784736"))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>
          {formatText(t("faq5"))}
          </Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>
          {formatText(t("answer5"))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>{t("faq1438785191")}</Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>
            {formatText(t("answer1438785191"))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography>
           {t("faq1549354752")}
          </Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>
            {formatText(t("answer1549354752"))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8a-content"
          id="panel8a-header"
        >
          <Typography>
           {t("faq3")}
          </Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>
            {formatText(t("answer3"))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9a-content"
          id="panel9a-header"
        >
          <Typography>{t("faq1438782020")}
            
          </Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>{formatText(t("answer1438782020"))}
          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10a-content"
          id="panel10a-header"
        >
          <Typography>{t("faq1438786108")}
            
          </Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>{formatText(t("answer1438786108"))}
            
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11a-content"
          id="panel11a-header"
        >
          <Typography>{t("faq7")}
            
          </Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>{formatText(t("answer7"))}
          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12a-content"
          id="panel12a-header"
        >
          <Typography> {t("faq1438786979")}
            
          </Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>
            {formatText(t("answer1438786979"))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12a-content"
          id="panel12a-header"
        >
          <Typography>{t("faq1438785737")}
          
          </Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography>{formatText(t("answer1438785737"))}
           
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <FAQQuestion
          color={theme.palette.text.secondary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-switch-edu-content"
          id="panel-switch-edu-header"
        >
          <Typography>{t("faq1581951904")}</Typography>
        </FAQQuestion>
        <AccordionDetails sx={{ bgcolor: "secondary.200", p: 3 }}>
          <Typography gutterBottom>
            {formatText(t("answer1581951904"))}
          </Typography>
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              paddingTop: "56.25%",
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <iframe
              src="https://tube.switch.ch/embed/4357455c"
              title="SWITCH-edu ID Authentication"
              frameBorder="0"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "inherit",
              }}
            ></iframe>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="body1">
          {t("for_questions_contact_xxx")}
          <Link
            sx={{ color: theme.palette.primary["500"],marginLeft:2 }}
            href="mailto:explab@unil.ch"
          >
            explab@unil.ch
          </Link>
          .
        </Typography>
      </Box>
    </Container>
  );
}
