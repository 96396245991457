import { createTheme } from "@mui/material";

export const oldTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "rgba(0, 140, 204, 1)",
      rgba: "rgba(0, 140, 204, 0.2)",
    },
    secondary: {
      main: "#edf2ff",
    },
    background: {
      default: "#ffffff",
      paper: "#f5f5f5",
    },
    text: {
      primary: "#000000",
    },
    gradient: {
      main: "linear-gradient(90deg, rgba(13,36,64,1) 0%, rgba(59,91,140,1) 43%, rgba(244,153,179,1) 100%)",
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#f499b3",
      rgba: "rgba(0, 140, 204, 0.2)",
    },
    secondary: {
      main: "#edf2ff",
    },
    background: {
      default: "#0a1d33",
      paper: "#172b44 !important",
    },
    text: {
      primary: "#ffffff",
    },
    table: {
      th: "#3d5066",
      tr: "#566679",
      td: "#566679",
    },
    gradient: {
      main: "linear-gradient(90deg, rgba(13,36,64,1) 0%, rgba(59,91,140,1) 43%, rgba(244,153,179,1) 100%)",
    },
  },
});

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fbc6d5",
      100: "#f498b4",
      200: "#f6a4bc",
      300: "#f8afc4",
      400: "#fabbcc",
      500: "#fbc6d5",
      600: "#fdd2dd",
    },
    secondary: {
      main: "#0d2440",
      100: "#0d2440",
      200: "#283853",
      300: "#283853",
      400: "#5a657b",
      500: "#747d90",
      600: "#8e95a5",
    },
    mix: {
      main: "#272f4b",
      100: "#272f4b",
      200: "#3d435d",
      300: "#535870",
      400: "#696d83",
      500: "#818497",
      600: "#999bab",
    },
    text: {
      primary: "#ffffff",
      secondary: "#283853",
    },
    action: {
      hover: "#fbc6d5",
    },
    gradient: {
      main: "linear-gradient(90deg, rgba(13,36,64,1) 0%, rgba(59,91,140,1) 43%, rgba(244,153,179,1) 100%)",
    },
    
  },
 
});
