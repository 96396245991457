import { createSlice } from "@reduxjs/toolkit";
import { getTranslations } from "../thunks/collections/getTranslations";

const collectionsSlice = createSlice({
  name: "collections",
  initialState: {
    lang: "fr",
    fields: [],
    loading: false,
    error: null,
    dark:false
  },
  reducers: {
    setLanguage: (state, action) => {
      state.lang = action.payload;
    },
    setDarkMode: (state, action) => {
      state.dark = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTranslations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTranslations.fulfilled, (state, action) => {
        state.fields = [...action.payload];
        state.loading = false;
      });
  },
});
export const {setLanguage,setDarkMode} = collectionsSlice.actions
export default collectionsSlice.reducer;
