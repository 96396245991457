import {
  Container,
  TextField
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const PersonalInformationEdu = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Container style={{ float: "left", marginRight: "2%" }}>
      <TextField
        label={t("firstname")}
        value={data.fname}
        fullWidth
        margin="normal"
        disabled={true}
      />

      <TextField
        label={t("lastname")}
        name="lastName"
        value={data.lname}
        fullWidth
        margin="normal"
        disabled={true}
      />

      <TextField
        label="Email"
        name="email"
        value={data.email}
        fullWidth
        margin="normal"
        disabled={true}
      />

      <TextField
        label={t("date_of_birth")}
        name="birthDate"
        value={data.date_of_birth}
        fullWidth
        margin="normal"
        disabled={true}
      />

      <TextField
        label={t("phone_number")}
        name="phoneNumber"
        value={data.phone_number}
        fullWidth
        margin="normal"
        disabled={true}
      />
    </Container>
  );
};

export default PersonalInformationEdu;
