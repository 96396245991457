import { useTheme } from "@emotion/react";
import {
  Button,
  Divider,
  styled,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiLogOut } from "react-icons/fi";
import { IoMenuSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useLogin from "../../hooks/useLogin";
import { setDarkMode, setLanguage } from "../../store/slice/collectionsSlice";
import { logout } from "../../store/slice/userSlice";
import i18n from "../../translation/languageModule";
import MobileNavigation from "./MobileNavigation";



const HeaderTab = styled(Tab)(({ theme, inView }) => ({
  color: theme.palette.text.primary,
  "&.Mui-selected": {
    color: theme.palette.primary["500"] + "!important",
  },
  "&:hover": {
    color: theme.palette.primary["100"] + "!important",
  },
}));

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const darkMode = useSelector((state) => state.collections.dark);
  const { handleLogin } = useLogin();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getInitialValue = () => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab !== null) {
      return Number(savedTab);
    } else {
      return 0;
    }
  };

  const [value, setValue] = useState(
    parseInt(localStorage.getItem("selectedTab"))
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("selectedTab", newValue);
  };

  const changeLanguage = (lang) => {
    dispatch(setLanguage(lang));
    i18n.changeLanguage(lang);
  };

  const handleToggle = (event) => {
    const isChecked = event.target.checked;
    dispatch(setDarkMode(isChecked));
  };

  if (mobile) {
    return <MobileNavigation />;
  }
  return (
    <AppBar
      position="sticky"
      sx={{ background: theme.palette.gradient.main, padding: ".5em 0" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters={true}>
          <Box
            noWrap
            display={"flex"}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              marginLeft: 0,
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("selectedTab", 0);
              setValue("/orsee");
              navigate(process.env.REACT_APP_PREFIX);
            }}
          >
            <img
              src="/logo-labex.png"
              alt="logo-labex"
              style={{
                width: "150px",
                height: "auto",
                cursor: "pointer",
                padding: 2,
              }}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              boxShadow: "none",
            }}
          >
            {isAuthenticated ? (
              <Tabs
                variant="scrollable"
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: {
                    width: 3,
                    color: theme.palette.text.primary,
                    backgroundColor:
                      localStorage.getItem("selectedTab") && value === 0
                        ? "none"
                        : "#f499b3",
                    display: "none",
                  },
                }}
                sx={{ boxShadow: "none" }}
              >
                <HeaderTab
                  value={1}
                  label={t("menu_exp")}
                  onClick={() => {
                    localStorage.setItem("selectedTab", 1);
                    navigate(process.env.REACT_APP_PREFIX);
                  }}
                  sx={{
                    "&.Mui-selected": {
                      color: theme.palette.primary["100"],
                    },
                  }}
                />
                <HeaderTab
                  value={2}
                  label={t("menu_profile")}
                  onClick={() => {
                    localStorage.setItem("selectedTab", 2);
                    navigate(process.env.REACT_APP_PREFIX + "/profile");
                  }}
                  sx={{
                    "&.Mui-selected": {
                      color: theme.palette.primary["100"],
                    },
                  }}
                />

                <HeaderTab
                  value={3}
                  label={t("menu_rules")}
                  sx={{
                    "&.Mui-selected": {
                      color: theme.palette.primary["100"],
                    },
                  }}
                  onClick={() => {
                    localStorage.setItem("selectedTab", 3);
                    navigate(process.env.REACT_APP_PREFIX + "/rules&policies");
                  }}
                />
                <HeaderTab
                  value={4}
                  label={t("menu_help")}
                  sx={{
                    "&.Mui-selected": {
                      color: theme.palette.primary["100"],
                    },
                  }}
                  onClick={() => {
                    localStorage.setItem("selectedTab", 4);
                    navigate(process.env.REACT_APP_PREFIX + "/help");
                  }}
                />
              </Tabs>
            ) : (
              <Tabs
                variant="scrollable"
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: {
                    width: 3,
                    backgroundColor:
                      localStorage.getItem("selectedTab") && value === 0
                        ? "none"
                        : "#fff",
                    display: "none",
                  },
                }}
                sx={{ boxShadow: "none" }}
              >
                <HeaderTab
                  value={3}
                  label={t("menu_rules")}
                  sx={{
                    "&.Mui-selected": {
                      color: theme.palette.primary["100"],
                    },
                  }}
                  onClick={() => {
                    localStorage.setItem("selectedTab", 3);
                    navigate(process.env.REACT_APP_PREFIX + "/rules&policies");
                  }}
                />
                <HeaderTab
                  value={1}
                  label={t("menu_help")}
                  sx={{
                    "&.Mui-selected": {
                      color: "#fff",
                    },
                  }}
                  onClick={() => {
                    localStorage.setItem("selectedTab", 1);
                    navigate(process.env.REACT_APP_PREFIX + "/help");
                  }}
                />
              </Tabs>
            )}
          </Box>
          {!isAuthenticated && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flexGrow: 1,
              }}
            >
              <Button
                variant="contained"
                onClick={handleLogin}
                sx={{
                  marginLeft: "auto",
                  backgroundColor: "white",
                  color: "primary.100",
                  fontSize: "1em",
                  marginRight: 0,
                  padding: "8px 16px",
                  borderRadius: "20px",
                  textTransform: "none",
                  fontWeight: "bold",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "primary.500",
                    color: "text.primary",
                  },
                }}
              >
                Login
              </Button>
            </Box>
          )}

          <Box sx={{ flexGrow: 0 }} marginRight={10} marginLeft={4}>
            <Tooltip title="Open Menus">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <IoMenuSharp />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{
                mt: "45px",
                "& ul": {
                  backgroundColor: "secondary.200",
                  padding: "2em",
                },
                width: "20%",
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key="en"
                onClick={() => {
                  setAnchorElUser(null);
                  changeLanguage("en");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.1em",
                  }}
                  textAlign="center"
                >
                  English
                </Typography>
              </MenuItem>
              <MenuItem
                key="fr"
                onClick={() => {
                  setAnchorElUser(null);
                  changeLanguage("fr");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.1em",
                  }}
                  textAlign="center"
                >
                  Français
                </Typography>
              </MenuItem>
              <MenuItem
                key="ger"
                onClick={() => {
                  setAnchorElUser(null);
                  changeLanguage("de");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.1em",
                  }}
                  textAlign="center"
                >
                  Deutsch
                </Typography>
              </MenuItem>

              {/* <MenuItem key="dark-mode">
                  <Typography textAlign="center">Dark Mode</Typography>
                  <Switch
                    checked={darkMode}
                    onChange={handleToggle}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ marginLeft: 1 }}
                  />
                </MenuItem>
                 */}

              {isAuthenticated && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <MenuItem
                    key="logout"
                    onClick={() => {
                      dispatch(logout());
                      setAnchorElUser(null);
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "primary.100",
                    }}
                  >
                    <Typography textAlign="center">Logout</Typography>
                    <FiLogOut />
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
