import { useTheme } from "@emotion/react";
import {
  Alert,
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import IBAN from "iban";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import * as Yup from "yup";
import axiosInstance from "../../api/axiosInstance";
import { useThunk } from "../../hooks/useThunk";
import { getProfile } from "../../store/thunks/user/getProfile";
import RulesManager from "../help/RulesManager";
import Stepper_ from "../other/Stepper_";
import Address from "../profile/web/Address";
import PaymentInformation from "../profile/web/PaymentInformation";
import PersonalInformation from "../profile/web/PersonalInformation";

const decodeParamsFromUrl = (encoded) => {
  try {
    const jsonString = atob(encoded);

    const decodedParams = JSON.parse(jsonString);

    return decodedParams;
  } catch (error) {
    console.error("Error decoding the parameters:", error);
    return null;
  }
};
const menuItems = [
  { value: "1043798985", label: "Elève" },
  { value: "1043799010", label: "Apprenti(e)" },
  { value: "1043799075", label: "Employé(e)" },
  { value: "1043799123", label: "Fonctionnaire" },
  { value: "1043799260", label: "Indépendant(e)" },
  { value: "1043799349", label: "Chômeur/Chômeuse" },
  { value: "1043799382", label: "Doctorant(e)" },
  { value: "1043799402", label: "Post-Doc" },
];

const EnrollPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.user);
  const [form, setForm] = useState([{}, {}, {}, { profession: "" }]);
  const [eduForm, setEduForm] = useState({});
  const [errors, setErrors] = useState({});
  const [runGetProfile, isLoadingProfile] = useThunk(getProfile);
  const [accept, setAccept] = useState(-1);
  const [skipped, setSkipped] = useState(new Set());
  const [showError, setShowError] = useState(false);
  const { fields } = useSelector((state) => state.collections);
  const { t } = useTranslation();
  const createParticipant = async () => {
    const formData = new FormData();

    [0, 1, 2].forEach((index) => {
      Object.keys(form[index]).forEach((key) => {
        formData.append(key, form[index][key]);
      });
    });

    Object.keys(form[3]).forEach((key) => {
      formData.append(key, form[3][key]);
    });

    formData.append("subpool_id", form[3].profession === "1043798985" ? 1 : 0);

    try {
      const response = await axiosInstance.post("/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Account created successfully please login!");
    } catch (error) {
      toast.error("Oups something went wrong please contact us");
    }
  };

  const personalInformationSchema = Yup.object().shape({
    mother_tongue: Yup.string().trim().required("Mother tongue is required"),
    other_language: Yup.string().trim(),
    gender: Yup.string()
      .oneOf(["m", "f", "non-binary", "other"])
      .required(t("you_have_to_gender")),
    institution: Yup.string().trim().required(t("error_no_institution")),
    field_of_studies: Yup.string()
      .trim()
      .required(t("you_have_to_field_of_studies")),
    begin_of_studies: Yup.number()
      .required(t("you_have_to_begin_of_studies"))
      .min(1900, "Invalid year")
      .max(new Date().getFullYear(), "Year cannot be in the future"),
  });

  const addressSchema = Yup.object().shape({
    street_name: Yup.string().trim().required(t("street_name_empty_error")),
    postal_code: Yup.string().trim().required(t("postal_code_empty_error")),
    city: Yup.string().trim().required(t("city_empty_error")),
    country: Yup.string().trim().required(t("country_empty_error")),
  });

  const paymentInformationSchema = Yup.object().shape({
    is_account_holder_same_name: Yup.string()
      .oneOf(["y", "n"])
      .required("Account holder status is required"),
    iban: Yup.string().when("is_account_holder_same_name", {
      is: "y",
      then: (schema) =>
        schema
          .required("IBAN is required")
          .test("is-valid-iban", "Invalid IBAN", (value) =>
            IBAN.isValid(value)
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
    account_holder_fname: Yup.string().when("is_account_holder_same_name", {
      is: "n",
      then: (schema) => schema.required(t("either_holder_lname_fname")),
      otherwise: (schema) => schema.notRequired(),
    }),
    account_holder_lname: Yup.string().when("is_account_holder_same_name", {
      is: "n",
      then: (schema) => schema.required(t("either_holder_lname_fname")),
      otherwise: (schema) => schema.notRequired(),
    }),
    country_of_bank: Yup.string().required(t("bank_country_missing")),
  });

  const schemas = [
    personalInformationSchema,
    addressSchema,
    paymentInformationSchema,
  ];

  useEffect(() => {
    if (userInfo.length === 0) {
      runGetProfile();
    }
  }, []);

  useEffect(() => {
    if (userInfo.length !== 0) {
      setForm(userInfo?.slice(0, 3));
      setEduForm(userInfo[3]);
    }
  }, [userInfo]);

  const handleDataChange = (newData) => {
    console.log(newData);
    if (newData?.target?.name === "is_account_holder_same_name") {
      const { name, value } = newData.target;
      setForm((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[activeStep - 2] = {
          ...updatedForm[activeStep - 2],
          [name]: value,
        };
        return updatedForm;
      });
    } else {
      setForm((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[activeStep - 2] = newData;
        return updatedForm;
      });
    }
  };

  const validateStep = async () => {
    const dataToValidate = form[activeStep - 2];
    const schema = schemas[activeStep - 2];

    if (!schema) return true;

    try {
      await schema.validate(dataToValidate, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error) {
      if (error?.inner) {
        const validationErrors = {};

        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      }
      return false;
    }
  };
  const steps = [
    { label: t("enroll_status") },
    { label: t("enroll_terms_conditions") },
    {
      label: t("enroll_personal_information"),
      continue: true,
      validate: validateStep,
    },
    { label: t("enroll_address"), continue: true, validate: validateStep },
    {
      label: t("enroll_bank_information"),
      continue: true,
      validate: validateStep,
    },
  ];

  useEffect(() => {
    if (activeStep === steps.length - 1) {
      createParticipant();
    }
  }, [activeStep]);

  const handleNext = async () => {
    setShowError(false);

    if (!form[3]?.profession) {
      setShowError(true);
      return;
    }

    if (accept < 1 && activeStep === 1) {
      return;
    }
    if (steps[activeStep].validate) {
      const isValid = await steps[activeStep].validate();
      if (!isValid) {
        setShowError(true);
        return;
      }
    }

    let newSkipped = skipped;
    if (skipped.has(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;

    const updatedForm = [...form];
    updatedForm[3] = {
      ...updatedForm[3],
      [name]: value,
    };

    setForm(updatedForm);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encoded = urlParams.get("t");
    if (encoded) {
      const params = decodeParamsFromUrl(encoded);
      setEduForm({ ...params });
    }
  }, []);

  return (
    <Container>
      <Typography variant="h3" sx={{ marginBottom: "2em" }}>
        {t("enroll_registering_process")}
      </Typography>

      <ToastContainer />

      <Stepper_
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={steps}
        skipped={skipped}
        setSkipped={setSkipped}
        handleBack={handleBack}
        handleNext={handleNext}
      >
        <Box
          sx={{
            margin: "2em auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {showError && (
            <Alert sx={{ marginBottom: 5 }} severity="error">
              {t("enroll_fill_form_warning")}
            </Alert>
          )}
          {activeStep === 0 && (
            <Box sx={{ minWidth: "360px", width: "100%", textAlign: "center" }}>
              <FormControl sx={{ textAlign: "center" }}>
                <FormLabel sx={{ color: "text.primary", fontSize: "2em" }}>
                  {t("enroll_current_status_question")}
                </FormLabel>
                <Select
                  labelId="select-status-label"
                  id="select-status"
                  name={"profession"}
                  value={form[4]}
                  onChange={handleChange}
                  sx={{ width: "100%", color: theme.palette.text.primary }}
                >
                  {menuItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {t(item.value)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {activeStep === 1 && (
            <Box sx={{ minWidth: "360px", width: "100%" }}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{
                  color: "text.primary",
                  fontSize: "2em",
                  textAlign: "center",
                  display: "block",
                }}
              >
                {t("menu_rules")}
              </FormLabel>
              <RulesManager />
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    color: "text.primary",
                    fontSize: "1.5em",
                    margin: "auto",
                  }}
                >
                  {t("enroll_agree_with_terms")}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ margin: "auto" }}
                >
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        sx={{ color: theme.palette.text.primary }}
                        onClick={() => {
                          setAccept(1);
                        }}
                      />
                    }
                    label="yes"
                    checked={accept === 1}
                  />
                  <FormControlLabel
                    value="0"
                    control={
                      <Radio
                        sx={{ color: theme.palette.text.primary }}
                        onClick={() => {
                          setAccept(0);
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          )}
          {activeStep === 2 && (
            <Box sx={{ minWidth: "360px", width: "100%", textAlign: "center" }}>
              <PersonalInformation
                data={form[0]}
                setData={handleDataChange}
                errors={errors}
              />
            </Box>
          )}

          {activeStep === 3 && (
            <Box sx={{ minWidth: "360px", width: "100%", textAlign: "center" }}>
              <Address
                data={form[1]}
                setData={handleDataChange}
                errors={errors}
              />
            </Box>
          )}

          {activeStep === 4 && (
            <Box sx={{ minWidth: "360px", width: "100%", textAlign: "center" }}>
              <PaymentInformation
                data={form[2]}
                personalInfo={eduForm}
                setData={handleDataChange}
                errors={errors}
              />
            </Box>
          )}
          {!accept && (
            <Alert severity="error">
              {t("enroll_accept_before_continuing")}
            </Alert>
          )}
        </Box>
      </Stepper_>
    </Container>
  );
};

export default EnrollPage;
