import { createSlice } from "@reduxjs/toolkit";
import { getExperienceDone } from "../thunks/experience/getExperienceDone";
import { getExperienceInvitation } from "../thunks/experience/getExperienceInvitation";
import { getExperienceRegistration } from "../thunks/experience/getExperienceRegistration";
import { getExperiencePayment } from "../thunks/experience/getExperiencePayment";

const initialState = {
  doneExperiences: [],
  experienceInvitations: [],
  experienceRegistrations: [],
  confirmationExperiences: [],
  loading: false,
  error: null,
};
const experienceSlice = createSlice({
  name: "experiences",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExperienceDone.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExperienceDone.fulfilled, (state, action) => {
        state.doneExperiences = [...action.payload];
        state.loading = false;
      })

      .addCase(getExperienceInvitation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExperienceInvitation.fulfilled, (state, action) => {
        state.experienceInvitations = [...action.payload];
        state.loading = false;
      })

      .addCase(getExperienceRegistration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExperienceRegistration.fulfilled, (state, action) => {
        state.experienceRegistrations = [...action.payload];
        state.loading = false;
      })

      .addCase(getExperiencePayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExperiencePayment.fulfilled, (state, action) => {
        state.confirmationExperiences = [...action.payload];
        state.loading = false;
      });
  },
});

export default experienceSlice.reducer;
