import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  useMediaQuery,
  styled,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/capitalizerLetter";
import { useTheme } from "@emotion/react";
import { boolean } from "yup";

const languages = [
  "English",
  "French",
  "German",
  "Spanish",
  "Italian",
  "Chinese",
  "Japanese",
  "Russian",
  "Portuguese",
  "Arabic",
  "Hindi",
  "Bengali",
  "Korean",
  "Vietnamese",
  "Turkish",
  "Persian",
  "Dutch",
  "Thai",
  "Greek",
  "Swedish",
  "Norwegian",
  "Danish",
  "Finnish",
  "Hebrew",
  "Polish",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 500,
    },
  },
};

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= 8; i++) {
    years.push(currentYear - i);
  }
  return years;
};

const FormTextfield = styled(TextField)(({theme, inView}) => ({
  color: theme.palette.text.primary,
  "& label": {
    color: theme.palette.text.primary
  },
  "&:hover": {
    color: theme.palette.primary['500'],
    "& label": {
      color: theme.palette.primary['500']+ " !important"
    },
    "& fieldset": {
      borderColor: theme.palette.primary['500'] +" !important"
    }
  }
}))

const PersonalInformation = ({ data, setData, errors, setErrors }) => {
  const { lang, fields } = useSelector((state) => state.collections);
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const institutionContentTypes = fields?.filter((item) =>
    item.content_type.includes("institution")
  );

  const fieldOfStudiesContentTypes = fields?.filter((item) =>
    item.content_type.includes("field_of_studies")
  );

  const motherTongueContentTypes = fields?.filter((item) =>
    item.content_type.includes("mother_tongue")
  );

  const handleDropdownChange = (event, fieldName, contentTypes) => {
    const { value } = event.target;
    const selectedItem = contentTypes.find(
      (item) => item.content_name === value
    );
    setData({
      ...data,
      [fieldName]: value,
      [`${fieldName}_label`]: selectedItem ? selectedItem[lang] : "",
    });
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };


  return (
    <Container sx={{ padding: !isMobile ? "20px" : 0 }}>
      <Box
        sx={{
          backgroundColor: "table.tr",
          padding: "20px",
          borderRadius: "8px",
          border: "1px solid #e0e0e0",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontSize: "1rem", fontWeight: "bold" }}
        >
          {t("personal_info_title").replace(/<\/?[^>]+(>|$)/g, "")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextfield
              label={t("mother_tongue")}
              variant="outlined"
              fullWidth
              margin="normal"
              name="mother_tongue"
              value={data.mother_tongue}
              onChange={(event) =>
                handleDropdownChange(
                  event,
                  "mother_tongue",
                  motherTongueContentTypes
                )
              }
              select
              error={ errors?.mother_tongue?.message || errors?.mother_tongue }
              helperText={errors?.mother_tongue?.message || errors?.mother_tongue }
              SelectProps={{
                MenuProps: MenuProps,
              }}
            >
              {motherTongueContentTypes.map((language) => (
                <MenuItem
                  key={language.content_name}
                  value={language.content_name}
                >
                  {language[lang]}
                </MenuItem>
              ))}
            </FormTextfield>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextfield
              label={t("other_language")}
              variant="outlined"
              fullWidth
              margin="normal"
              name="other_language"
              value={data.other_language || ""}
              onChange={handleInputChange}
              select
              error={errors?.other_language}
              helperText={errors?.other_language}
              SelectProps={{
                MenuProps: MenuProps,
              }}
            >
              {languages.map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </FormTextfield>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextfield
              label={t("gender")}
              variant="outlined"
              fullWidth
              margin="normal"
              name="gender"
              value={data.gender}
              onChange={handleInputChange}
              select
              error={errors?.gender?.message || errors?.gender }
              helperText={errors?.gender?.message || errors?.gender}
            >
              <MenuItem value="m">
                {capitalizeFirstLetter(t("gender_m"))}
              </MenuItem>
              <MenuItem value="f">
                {capitalizeFirstLetter(t("gender_f"))}
              </MenuItem>
              <MenuItem value="non-binary">
                {capitalizeFirstLetter(t("gender_nb"))}
              </MenuItem>
              <MenuItem value="other">
                {capitalizeFirstLetter(t("gender_o"))}
              </MenuItem>
            </FormTextfield>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextfield
              label={t("institution")}
              variant="outlined"
              fullWidth
              margin="normal"
              name="institution"
              value={data.institution}
              onChange={(event) =>
                handleDropdownChange(
                  event,
                  "institution",
                  institutionContentTypes
                )
              }
              select
              error={errors?.institution?.message || errors?.institution}
              helperText={ errors?.institution?.message || errors?.institution }
              SelectProps={{
                MenuProps: MenuProps,
              }}
            >
              {institutionContentTypes.map((institution) => (
                <MenuItem
                  key={institution.content_name}
                  value={institution.content_name}
                >
                  {institution[lang]}
                </MenuItem>
              ))}
            </FormTextfield>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextfield
              label={t("studies")}
              variant="outlined"
              fullWidth
              margin="normal"
              name="field_of_studies"
              value={data.field_of_studies}
              onChange={(event) =>
                handleDropdownChange(
                  event,
                  "field_of_studies",
                  fieldOfStudiesContentTypes
                )
              }
              select
              error={errors?.field_of_studies?.message || errors?.field_of_studies}
              helperText={errors?.field_of_studies?.message ||  errors?.field_of_studies}
              SelectProps={{
                MenuProps: MenuProps,
              }}
            >
              {fieldOfStudiesContentTypes.map((field) => (
                <MenuItem key={field.content_name} value={field.content_name}>
                  {field[lang]}
                </MenuItem>
              ))}
            </FormTextfield>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextfield
              label="Study Start Year"
              variant="outlined"
              fullWidth
              margin="normal"
              name="begin_of_studies"
              value={data.begin_of_studies}
              onChange={handleInputChange}
              select
              error={ errors?.begin_of_studies?.message ||errors?.begin_of_studies}
              helperText={errors?.begin_of_studies?.message || errors?.begin_of_studies}
              SelectProps={{
                MenuProps: MenuProps,
              }}
            >
              {getYears().map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </FormTextfield>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PersonalInformation;
