import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";

const Rules = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Rules
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" paragraph>
          To participate in the experiments conducted by the HEC-LABEX
          experimental laboratory, you must create an online participant profile
          on this site. By registering, you declare your intention to take part
          in the experiments organized by members of the HEC-LABEX experimental
          laboratory.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Participation Rules:
        </Typography>

        <Typography variant="body1" component="ul">
          <li>You must be at least 18 years old;</li>
          <li>
            Only one participant profile is allowed per person: it is forbidden
            to register with multiple email addresses or under multiple
            identities, under penalty of being excluded from our database;
          </li>
          <li>
            For each experiment, a certain number of registered individuals will
            receive an invitation email. Several time slots will then be
            offered, and you can choose the one that suits your availability. In
            principle, only invited individuals can participate in the
            experiment in question. Therefore, it is forbidden to forward the
            invitation email to others and/or register for an experiment with a
            participant profile that is not your own;
          </li>
          <li>
            Individuals who agree to participate in an experiment by registering
            for a chosen session should keep in mind that the session may be
            canceled if they do not show up or arrive late, penalizing everyone;
          </li>
          <li>
            In the case of online experiments, only one participation in the
            same experiment is accepted. Any other participation in the same
            experiment will not be remunerated;
          </li>
          <li>
            If you have more than 3 unannounced absences per semester, you will
            be excluded from our database;
          </li>
          <li>
            <strong>Overbooking:</strong> To ensure a minimum number of
            participants, we sometimes invite a slightly higher number of
            individuals than desired. In this case, either the additional
            participants voluntarily give up their participation, or we
            determine by random draw which individuals should withdraw. In both
            cases, the individuals who no longer participate in the experiment
            receive compensation. Indeed, each invited and registered
            participant who shows up on time will receive at least a
            participation fee;
          </li>
          <li>
            The compensation for each participant is made in cash or by bank
            transfer. The exact amount depends on the nature of the experiment,
            your decisions, and the decisions of other participants. You will be
            informed of the amount or range of compensation before starting the
            experiment via the consent form. Please note that only individuals
            with a valid student card or ID will receive compensation;
          </li>
          <li>
            During the experiment, the participant must follow the rules as
            stated in the instructions provided at the beginning of each
            experiment. If you decide to withdraw after reading the instructions
            or during the experiment, you will not receive any compensation;
          </li>
          <li>
            <strong>Important:</strong> Once registered for an experiment, you
            cannot cancel your registration yourself. To do so, and only for
            exceptional reasons, you must contact our laboratory by email as
            soon as possible. Therefore, please check your availability before
            registering!
          </li>
          <li>
            Your registration in the recruitment system is valid for an
            indefinite period. This registration can be canceled at any time
            without notice by you or by the HEC-LABEX laboratory;
          </li>
        </Typography>

        <Typography variant="body1" paragraph>
          For any additional questions, please consult our FAQs or send an email
          to <Link sx={{color: 'primary.500'}} href="mailto:explab@unil.ch">explab@unil.ch</Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default Rules;
