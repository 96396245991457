import { configureStore } from '@reduxjs/toolkit';
import { FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import userSlice from './slice/userSlice';
import experienceSlice from './slice/experienceSlice';
import collectionsSlice from './slice/collectionsSlice';

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['isAuthenticated', 'userInfo'],
};

const collectionsPersistConfig = {
  key: 'collections',
  storage,
  whitelist: ['lang', "fields"],
};

const persistedUserReducer = persistReducer(userPersistConfig, userSlice);
const persistedCollectionsReducer = persistReducer(collectionsPersistConfig, collectionsSlice);

const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    experience: experienceSlice,
    collections: persistedCollectionsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
  
    })
});

export const persistor = persistStore(store);
export default store;
