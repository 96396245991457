import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import { setOtherInfo } from "../../slice/userSlice";

export const checkSession = createAsyncThunk(
  "user/checkSession",
  async (credentials, thunkAPI) => {
    try {
      const { data } = await axiosInstance("/refresh");

      return data;
    } catch (error) {
      localStorage.removeItem("jwtToken");

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
