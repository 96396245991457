import { useTheme } from "@emotion/react";
import GroupIcon from "@mui/icons-material/Group";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SchoolIcon from "@mui/icons-material/School";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { keyframes, styled, useMediaQuery } from "@mui/system";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getEduIDUrl } from "../utils/EduIDConnection";
import { useTranslation } from "react-i18next";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;

const CTAButton = styled(Button)(({ theme, inVuew }) => ({
  backgroundColor: theme.palette.primary["500"],
  color: theme.palette.text.secondary,
  "& svg": {
    color: theme.palette.text.secondary,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary["100"],
    color: theme.palette.text.primary,
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
}));

const AnimatedBox = styled(Box)(({ theme, inView }) => ({
  animation: inView ? `${fadeInUp} 1s ease-out forwards` : "none",
  opacity: inView ? 1 : 0,
}));

const AnimatedIcon = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  backgroundColor: theme.palette.text.primary,
  borderRadius: "50%",
  width: "80px",
  height: "80px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  animation: `${bounce} 2s infinite ease-in-out`,
}));

const ContactForm = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: "20px",
  textAlign: "center",
  backgroundImage: "none",
  boxShadow: theme.shadows[3],
}));


const formatText = (text) => {
  const formattedText = text
    .replace(
      /\[rules & policies\]\(link\)/g,
      `<a href="${process.env.REACT_APP_PREFIX}/rules&policies" target="_blank" rel="noopener noreferrer">rules & policies</a>`
    )
    .replace(/<br \/>/g, "\n"); 

  return formattedText;
};

const LandingPage = () => {
  const [refParticipant, inViewParticipant] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [refBenefits, inViewBenefits] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [refContactForm, inViewContactForm] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMailto = () => {
    window.location.href =
      "mailto:explab@unil.ch?subject=Inquiry%20about%20Research%20Participation&body=Hello%20LabEx,%0D%0A%0D%0AI%20would%20like%20to%20learn%20more%20about%20participating%20in%20your%20research%20studies.%20Please%20provide%20me%20with%20additional%20information.%0D%0A%0D%0AThank%20you!%0D%0A%0D%0A[Your%20Name]";
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: "3rem 0",
        "@media(max-width: 900px)": {
          padding: "0 1em 1em 1em",
        },
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: "4rem",
          borderRadius: "20px",
          marginBottom: "4rem",
          backgroundImage: "none",
          backgroundColor: "secondary.200",
          "@media(max-width: 900px)": {
            padding: "1em",
            marginBottom: "1em",
            "& h1": {
              fontSize: "2em",
            },
            "& div": {
              height: "auto !important",
            },
            "& video": {
              height: "auto !important",
            },
          },
        }}
      >
        <Grid
          container
          spacing={4}
          alignItems="center"
          ref={refParticipant}
          direction={isMobile ? "column-reverse" : "row"}
        >
          <Grid item xs={12} md={6}>
            <AnimatedBox inView={inViewParticipant}>
              <Typography variant="h3" component="h1" gutterBottom>
                {t("earn_money_title")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "1.5rem",
                  fontSize: "1.1rem",
                  lineHeight: 1.8,
                }}
              >
               {t("earn_money_desc")}
              </Typography>
              <Typography marginTop={2}>
               {formatText(t("enroll_rules_and_switch_eduid"))}
              </Typography>
              <Box sx={{ textAlign: "left", marginTop: "2.5rem" }}>
                <CTAButton
                  variant="contained"
                  endIcon={<SchoolIcon />}
                  size="large"
                  onClick={() => (window.location.href = getEduIDUrl())}
                >
                  {t("register")}
                </CTAButton>
              </Box>
            </AnimatedBox>
          </Grid>
          <Grid item xs={12} md={6} classes="videoContainer">
            <ReactPlayer
              url={"https://labex.unil.ch/data/orsee_promo.mp4"}
              playing
              muted
              controls
              width="100%"
              height="315px"
              style={{ borderRadius: "15px" }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={4}
        sx={{
          padding: "4rem",
          borderRadius: "20px",
          marginBottom: "4rem",
          backgroundImage: "none",
          backgroundColor: "secondary.200",

          "@media(max-width: 900px)": {
            padding: "1em",
            marginBottom: "1em",
            "& div": {
              padding: "1em",
            },
          },
        }}
      >
        <Grid container spacing={4} padding={5} ref={refBenefits}>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <AnimatedIcon>
              <MonetizationOnIcon fontSize="large" color="primary" />
            </AnimatedIcon>
            <Typography variant="h5" component="h3" gutterBottom>
              {t("section_2_earn_money_title")}
            </Typography>
            <Typography variant="body1">
              {t("section_2_earn_money_description")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <AnimatedIcon>
              <SchoolIcon fontSize="large" color="primary" />
            </AnimatedIcon>
            <Typography variant="h5" component="h3" gutterBottom>
              {t("section_2_flexible_schedule_title")}
            </Typography>
            <Typography variant="body1">
              {t("section_2_flexible_schedule_description")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <AnimatedIcon>
              <GroupIcon fontSize="large" color="primary" />
            </AnimatedIcon>
            <Typography variant="h5" component="h3" gutterBottom>
              {t("section_2_join_community_title")}
            </Typography>
            <Typography variant="body1">
              {t("section_2_join_community_description")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <ContactForm
        elevation={4}
        ref={refContactForm}
        sx={{ backgroundColor: "secondary.200" }}
      >
        <AnimatedBox inView={inViewContactForm}>
          <Typography variant="h4" component="h2" gutterBottom>
            {t("section_contact_us_title")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("section_3_questions_description")}
          </Typography>
          <Box sx={{ marginTop: "2rem" }}>
            <CTAButton variant="contained" size="large" onClick={handleMailto}>
              {t("section_3_send_email_title")}
            </CTAButton>
          </Box>
        </AnimatedBox>
      </ContactForm>
    </Container>
  );
};

export default LandingPage;
