import React from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useExperienceData from "../../../hooks/useExperienceData";
import Loading from "../../other/Loading";
import Experiences from "../Experiences";

const PaymentExperience = () => {
  const { confirmationExperiences, loading, error } = useExperienceData();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 600px)");

  if (loading) {
    return <Loading />;
  }

  if (!confirmationExperiences.length) {
    return (
      <Container
        sx={{
          padding: isMobile ? 1 : 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "60vh",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          {t("No experiences for payment confirmation.")}
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        padding: isMobile ? 1 : 3,
        maxWidth: "1000px",
        margin: "0 auto",
      }}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: 600, 
            textTransform: "uppercase", 
            letterSpacing: 1,
            marginBottom: 2, 
            borderBottom: "2px solid #1976d2", 
            paddingBottom: 1, 
          }}
        >
          {t("experiments_paid_confirmation")}
        </Typography>
        <Experiences data={confirmationExperiences} type={"payment"} />
      </Box>
      <Box marginBottom={5} />
    </Container>
  );
};

export default PaymentExperience;
