import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { useThunk } from "../../hooks/useThunk";
import { getTranslations } from "../../store/thunks/collections/getTranslations";
import { extractRoomUrl } from "../utils/extractRoomUrl";

const LocationDialog = ({ open, onClose, location }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    loading: fieldLoading,
    fields,
    lang,
  } = useSelector((state) => state.collections);
  const [runGetTranslations, isLoadingField, errorField] =
    useThunk(getTranslations);

  useEffect(() => {
    if (!fields.length && !fieldLoading) {
      runGetTranslations();
    }
  }, [fields.length, fieldLoading, runGetTranslations]);

  if (!location) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullScreen={isMobile}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2, 
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", p: 0, m: 0 }}>
          Experience Location
        </DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ color: theme.palette.text.primary,marginRight:2 }}
        >
          <IoMdCloseCircleOutline fontSize={"1.2em"} />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        {" "}
       
          {extractRoomUrl(location, fields, lang)?.url && (
            <iframe
              id="iframe-id"
              src={extractRoomUrl(location, fields, lang)?.url}
              style={{
                border: 0,
                width: "100%", 
                height: !isMobile ? 400:"100%", 
              }}
              title="Experience Location"
            ></iframe>
          )}
      </DialogContent>
    </Dialog>
  );
};

export default LocationDialog;
